<template>
    <div>
        <div class="hp-scormCon">
            <component
                :is="scormVersion"
                v-model="scormUrl"
                :res-id="resId"
                :update-id="updateId"
                :api-data="apiData"
            />
        </div>
    </div>
</template>
<script>
import Scorm12 from '../scrom/scorm2.vue';
import Scorm2004 from '../scrom/scorm.vue';
let taskId = 0;
let courseId = 0;
let courseItemId = 0;
export default {
    name:'commonScrom',
    components: {
        Scorm2004,
        Scorm12
    },
    props: {
        version: {
            type: String,
            default: '1.2'
        },
        id: {
            type: Number,
            default: 0
        },
        courseItemId:{
            type:Number,
            default:0
        }
    },
    data() {
        return {
            showSlider: true,
            scormVersion: '',
            Menulist: [],
            curActive: '1',
            courseName: '',
            catalogName: '',
            scormUrl: '',
            resId: 0,
            objapi: '',
            apiData: [],
            catalogId: '',
            updateId:'',
            duration:0,
            cleartime:'',
        };
    },
    watch:{
        id: {
				deep: true,
				immediate: true, // 这句重要
				handler(val) {
                    this.resId =Number(val);
                    
                    this.getScormInfoById();
				}
            },
            courseItemId:{
                deep: true,
				immediate: true, // 这句重要
				handler(val) {
                    courseItemId= val;
				}
                
            }
    },
    created() {
        taskId = this.$route.query.taskId;
        courseItemId = this.courseItemId;
        courseId =  this.$route.query.courseId;
        if (this.version === '1.2') {
            this.scormVersion = 'Scorm12';
        } else {
            this.scormVersion = 'Scorm2004';
        }
    },
    beforeMount() {

    },
    mounted() {
        this.resId = this.id;
        this.getScormInfoById();
        this.cleartime=setInterval(()=>{
            this.saveLearningLog();
            this.postUserLearningLog();
        },5000)
    },
    methods: {
         //记录用户学习时长
        async postUserLearningLog () {
        // if (!this.videoInfo.video) {
        //   return;
        // }
            const data = {
                learningLength: 5
            }
            await this.$api.course.postUserLearningLog({ data })
            },
        async saveLearningLog() {
            const data = {
                courseId: courseId,
                taskId: taskId || '',
                courseItemId: courseItemId,
                cur: 5 || 1,
                max: 5 || 1,
                pollingTime: 5,
                taskType:2
            }
            return await this.$api.course.postCourseLearningLogSaveLog({ data }).then(res => {
                console.log(res);
            })
        },
        // 课程基本信息回显
        async getScormInfoById() {
            const params = {
                id: this.resId,
            };
            try {
                const { data } = await this.$api.zuoyeResource.getScormInfoById(params);
                if (data.sourceSign === '1.2') {
                    this.scormVersion = 'Scorm12';
                } else {
                    this.scormVersion = 'Scorm2004';
                }
                if(data.scoCatalogDtoList){
                    // window.location.hostname
                    this.scormUrl = 'https://' + window.location.hostname + data.scoCatalogDtoList[0].url;
                    // this.scormUrl = 'https://hrss-main.testing3.wdeduc.com' + data.scoCatalogDtoList[0].url;
                    // this.scormUrl = data.scoCatalogDtoList[0].visitUrl
                    // this.apiData = [
                    //     {"scoId":"","variableKey":"cmi.student_preference.audio","variableValue":"1"},
                    //     {"scoId":"","variableKey":"mos.timestampTentative","variableValue":"1610967403"},
                    //     {"scoId":"","variableKey":"cmi.core.lesson_status","variableValue":"incomplete"},
                    //     {"scoId":"","variableKey":"cmi.core.lesson_location","variableValue":"0$ā2"},
                    //     {"scoId":"","variableKey":"cmi.core.exit","variableValue":"logout"},
                    //     {"scoId":"","variableKey":"mos.dateRemonte","variableValue":"2021-01-18T10:56:49.91"}]
                    if(data.scoCatalogDtoList[0].parameter) {
                        this.apiData.push({
                            scoId: this.playerScoId,
                            variableKey: 'ro.launch_data',
                            variableValue: data.scoCatalogDtoList[0].parameter,
                        });
                    }
                }
            } catch (err) {
                console.debug(err);
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        next();
    },
    beforeDestroy(){
        this.saveLearningLog();
        clearInterval(this.cleartime)
    },
    destroyed() {
        
    }
};
</script>

<style lang="stylus">
    .hp-scormCon {
       padding:20px;
    }
</style>
