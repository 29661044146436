<template>
    <!-- eslint-disable  -->
	<div>
		<!--<button @click="toCommit" style="padding-top: 100px;">commit</button>-->
		<iframe :src="scormUrl" style="width: 100%;height: 100vh;overflow:hidden;background:#fff;" allowfullscreen></iframe>

	</div>
</template>

<script>
	export default {
		props: {
			value: {
				type: String,
				default: ''
			},
			resId: {
				type: String,
				default: ''
			},
			apiData:{
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				scormUrl: '',
				objapi: '',
				infoLMSSpe: "infoLMSSpe",
				playerScoId: "",
				persisteSSP: false,
				errorCode: 0,
				dateTentativeEnvoi: "",
				objectifCible: null,
				etatCommunication: "pasInitialise",
				scoEnAttente: false,
				cibleChoix: "",
				modifications: false,
				modificationAlloc: false,
				changementEtatCompletion: false, //判断当前完成状态是否改变
				changementEtatObjectif: false,
				changementObjectifSecondaire: false,
				changementInteraction: false,
				changementEtatSession: false,
				changementScore: false,
				depiste: true,
				derniereAffectation: "",
				valeurDerniereAffectation: "",
				// Variables normalement externes à l'API
				abandonEnCours: false,
				oldId: '',
			}
		},
		watch: {
			resId: {
				deep: true,
				immediate: true, // 这句重要
				handler(val) {
					this.etatCommunication = "";
					if(val) this.getScormInfoById();
					if(this.oldId == '') this.oldId = val;
				}
			},
			value: {
				deep: true,
				immediate: true, // 这句重要
				handler(val) {
					if(val) this.scormUrl = val;
				}
			},
			apiData : {
				deep: true,
				immediate: true, // 这句重要
				handler(val) {
					if(val) this.apiData = val;
				}
			}

		},
		methods: {
			toCommit() {
				this.objapi.Commit();
			},
			//获取数据
			async getScormInfoById() {
					this.initFunc();
			},
			//在DICO中查找，如果找不到，返回默认值。
			GetRawValueOrDefault(keyName, valDefault) {
				var attr = this.GetRawValue(keyName);
				if(attr == null) {
					attr = valDefault;
				}
				return attr;
			},
			//在dico中搜索，如果未找到则返回null
			GetRawValue(keyName) {
				if(this.apiData.length > 0) {
					for(var z in this.apiData) {
						if(this.apiData[z].variableKey == keyName) {
							return this.apiData[z].variableValue;
						}
					}
				}

				return null;
			},
			//增加2个ISO持续时间
			GetIsoIntervalSum(ajoutrvl, intvrl) {
				if(intvrl == "PT0S" || intvrl == "") {
					return ajoutrvl;
				}
				var totalms = this.ConvertIsoIntervalToMs(ajoutrvl) + this.ConvertIsoIntervalToMs(intvrl);
				return this.ConvertSecondIntervalToIso(totalms / 1000);
			},
			//将ISO时间转换为ms
			ConvertIsoIntervalToMs(t) {
				var strTemps = new String(t);
				var indLettreT = strTemps.indexOf('T');
				var ajoutDate = 0;
				if(indLettreT < 0) {
					indLettreT = strTemps.length;
				}
				if(indLettreT > 1) {
					// Y, M ou D
					var strDate = strTemps.substring(1, indLettreT);
					var annee = 0;
					var indY = strDate.indexOf("Y");
					if(indY > 0) {
						annee = parseInt(strDate.substring(0, indY));
						strDate = strDate.substring(indY + 1);
					}
					var mois = 0;
					indY = strDate.indexOf("M");
					if(indY > 0) {
						mois = parseInt(strDate.substring(0, indY), 10);
						strDate = strDate.substring(indY + 1);
					}
					var jours = 0;
					indY = strDate.indexOf("D");
					if(indY > 0) {
						jours = parseInt(strDate.substring(0, indY), 10);
					}
					var multiJ = 3600 * 24 * 1000;
					ajoutDate = jours * multiJ + mois * multiJ * 30 + annee * multiJ * 365;

				}
				strTemps = strTemps.substring(indLettreT + 1);
				indY = strTemps.indexOf("H");
				var heures = 0;
				if(indY > 0) {
					heures = parseInt(strTemps.substring(0, indY), 10);
					strTemps = strTemps.substring(indY + 1);
				}
				indY = strTemps.indexOf("M");
				var min = 0;
				if(indY > 0) {
					min = parseInt(strTemps.substring(0, indY), 10);
					strTemps = strTemps.substring(indY + 1);
				}
				indY = strTemps.indexOf("S");
				var sec = 0;
				if(indY > 0) {
					sec = Number(strTemps.substring(0, indY));
				}
				return(((heures * 3600) + (min * 60) + sec) * 1000) + ajoutDate;
			},
			//将s中的时间转换为ISO
			ConvertSecondIntervalToIso(ts) {
				var sansDeci = Math.floor(ts);
				var partieDeci = ts - sansDeci;
				ts = sansDeci;
				var sec = (ts % 60);
				ts -= sec;
				if(partieDeci > 0) {
					sec += partieDeci;
					sec = new String(sec);
					var indp = sec.indexOf(".");
					if((sec.length - indp) > 3) {
						sec = sec.substring(0, indp + 3);
					}
				}
				var tmp = (ts % 3600);
				ts -= tmp;
				var hour, min;
				if((ts % 3600) != 0)
					hour = 0;
				else hour = (ts / 3600);
				if((tmp % 60) != 0)
					min = 0;
				else min = (tmp / 60);
				var jour = 0;
				if(hour >= 24) {
					var resth = (hour % 24);
					jour = parseInt(hour / 24);
					hour = resth;
				}
				// alert(" jour "+jour+" - hour "+hour+" - sec "+sec);
				return this.ConvertDHMSToISO(jour, hour, min, sec);
			},
			//将JHMS中的时间转换为ISO
			ConvertDHMSToISO(jour, strHour, strMin, strSec) {
				var strTime = "P";
				if(jour > 0) {
					strTime += jour + "D";
				}
				var intHour = parseInt(strHour, 10);
				var intMin = parseInt(strMin, 10);
				var intSec = Number(strSec, 10);
				if(intHour > 0 || intMin > 0 || intSec > 0) {
					strTime += "T";
					if(intHour > 0) strTime += intHour + "H";
					if(intMin > 0) strTime += intMin + "M";
					if(intSec > 0) {
						strTime += (intSec + "S");
					}
				}
				if(strTime == "P") strTime = "PT0H0M0S";
				return strTime;
			},
			//计算当前进度
			ComputeCompletionStatus(oldValue) {
				var seuil = this.objapi.GetValue("cmi.completion_threshold"); //获取全部完成进度值
				if(seuil != "") {
					var computedValue = "unknown";
					var valProg = this.objapi.GetValue("cmi.progress_measure"); //获取当前进度值
					if(valProg != "") {
						if(Number(seuil) > Number(valProg)) {
							computedValue = "incomplete";
						} else if(Number(seuil) <= Number(valProg)) {
							computedValue = "completed";
						}
					}
					this.SetRawValue("cmi.completion_status", computedValue);
				}
				var currentValue = this.GetRawValueOrDefault("cmi.completion_status", "unknown");
				if(oldValue != currentValue) this.changementEtatCompletion = true; //判断当前完成状态是否改变
				this.errorCode = "0";
				return currentValue;
			},
			//计算是否成功
			ComputeSuccessStatus(oldValue) {
				var seuil = this.objapi.GetValue("cmi.scaled_passing_score");
				if(seuil != "") {
					var computedValue = "unknown";
					var valProg = this.objapi.GetValue("cmi.score.scaled");
					if(valProg != "") {
						if(Number(seuil) > Number(valProg)) {
							computedValue = "failed";
						} else if(Number(seuil) <= Number(valProg)) {
							computedValue = "passed";
						}
					}
					this.SetRawValue("cmi.success_status", computedValue);
				}

				var currentValue = this.GetRawValueOrDefault("cmi.success_status", "unknown");
				var objectifPrincipalIndex = this.GetRawValue("ro.objectives._primary.index");
				if((oldValue != currentValue) || ((objectifPrincipalIndex != null) && (this.GetRawValue("cmi.objectives." + objectifPrincipalIndex + ".success_status") != "true"))) {
					this.changementEtatObjectif = true;
					this.SetRawValue("mos.modifObjPrinc", "oui");
				}
				this.errorCode = "0";
				return currentValue;
			},
			//在DICO中查找，如果找不到，返回标准错误。
			GetRawValueOrError(keyName) {
				var attr = this.GetRawValue(keyName);
					if(attr == null) {
						this.errorCode = "403";
						return "";
					}
				return attr;
			},
			StringEndsWith(string, pattern) {
				if(string.length >= pattern.length) {
					return(string.substr(string.length - pattern.length) == pattern);
				} else {
					return false;
				}
			},
			debug(message, forced) {
				var debug = false;
				if(console && (forced || debug)) {
					console.debug(window.console, message);
				}
			},
			//返回变量名称的root，直到第n个组件（包括最后一个点）
			GetKeyUntilNthRoot(scoKey, maxRootIndex) {
				var result = scoKey;
				var tab = scoKey.split(".");
				if(tab.length >= maxRootIndex) {
					result = "";
					for(var cptIndex = 0; cptIndex < maxRootIndex; cptIndex++) {
						result = result + tab[cptIndex];
						if(tab.length != maxRootIndex) result = result + ".";
					}
				}
				return result;
			},
			//返回键的第n部分
			GetKeyNthRoot(scoKey, rootIndex) {
				var result = "";
				var tab = scoKey.split(".");
				if(tab.length >= rootIndex) {
					result = tab[rootIndex];
				}
				return result;
			},
			//检查localdb是否至少有一个具有给定前缀的值
			LocalDbHasAtLeastOnePrefix(prefix) {
				if(this.apiData.length > 0) {
					for(var z in this.apiData) {
						if(this.apiData[z].variableKey.indexOf(prefix) == 0) {
							return true;
						}
					}
				}
				return false;
			},
			//如果值是允许的词汇表的一部分，则分配变量
			SetRawValueFromVocabulary(key, value, vocab) {
				vocab = "," + vocab + ",";
				if(vocab.indexOf("," + value + ",") >= 0) {
					this.SetRawValue(key, value);
					return "true";
				}
				this.infoLMSSpe = "Value '" + value + "' not in vocabulary";
				this.errorCode = "406";
				return "false";
			},
			// 设置版本RAW
			SetRawValue(key, value) {
				if(this.apiData.length > 0) {
					for(var z in this.apiData) {
						if(this.apiData[z].variableKey == key) {
							this.apiData[z].variableValue = String(value);
							return;
						}
					}
				}
				this.apiData.push({
					scoId: this.playerScoId,
					variableKey: key,
					variableValue: String(value)
				});
			},
			//验证是否处于范围内
			CheckBoundedReal(val, b0, b1) {
				var erreur = "0";
				var num = Number(val);
				if(isNaN(num)) {
					erreur = "406";
					this.infoLMSSpe = "Not a number";
				}
				if(b0 != "*") {
					if(num < Number(b0)) {
						erreur = "407";
						this.infoLMSSpe = "Less than range";
					}
				}
				if(b1 != "*") {
					if(num > Number(b1)) {
						erreur = "407";
						this.infoLMSSpe = "Greater than range";
					}
				}
				return erreur;
			},
			//删除其键以给定前缀开头的所有条目
			LocalDbDeleteAllPrefix(prefix) {
				return;
				/* eslint-disable */
				if(this.apiData.length > 0) {
					for(var z in this.apiData) {
						if(this.apiData[z].variableKey.indexOf(prefix) == 0) {
							delete this.apiData[z];
						}
					}
				}
				var result = [];
				for(var k in this.apiData)
					result.push(this.apiData[k]);
				this.apiData = result;
			},
			//将前缀的凭证复制到另一个前缀（来自LMS的valium副本）
			LocalDbDuplicatePrefixedValues(fromPrefix, toPrefix) {
				var temp = [];
				// On constitue la liste des valeurs à ajouter
				if(this.apiData.length > 0) {
					for(var z in this.apiData) {
						if(this.apiData[z].variableKey.indexOf(fromPrefix) == 0) {
							temp.push({
								scoId: this.apiData[z].scoId,
								variableKey: toPrefix + this.apiData[z].variableKey.substr(fromPrefix.length),
								variableValue: this.apiData[z].variableValue
							});
						}
					}
				}
				// On procède à l'ajout
				for(var k in temp) {
					//this.apiData.push(temp[z]);
					// Sécurisation du process avec SetRawValue de sorte à ne pas avoir de doublons
					this.SetRawValue(temp[k].variableKey, temp[k].variableValue);
				}
			},
			//检查语言代码的格式
			CheckLanguageCodeFormat(val) {
				if(val == "") {
					return true;
				}
				var resm = val.match(/^\w{2,3}(-\w{2,8})?$/);
				if(resm == null) {
					var res = false;
					// dans la liste a la con
					var mIANALangauges = ",i,x,art-lojban,az-arab,az-cyrl,az-latn,cel-gaulish,de-1901,de-1996,de-at-1901,de-at-1996,de-ch-1901,de-ch-1996,de-de-1901,de-de-1996,en-boont,en-gb-oed,en-scouse,i-ami,i-bnn,i-default,i-enochian,i-hak,i-klingon,i-lux,i-mingo,i-navajo,i-pwn,i-tao,i-tay,i-tsu,no-bok,no-nyn,sgn-be-fr,sgn-be-nl,sgn-br,sgn-ch-de,sgn-co,sgn-de,sgn-dk,sgn-es,sgn-fr,sgn-gb,sgn-gr,sgn-ie,sgn-it,sgn-jp,sgn-mx,sgn-nl,sgn-no,sgn-pt,sgn-se,sgn-us,sgn-za,sl-rozaj,sr-cyrl,sr-latn,uz-cyrl,uz-latn,yi-latn,zh-hans,zh-hant,zh-gan,zh-guoyu,zh-hakka,zh-min,zh-min-nan,zh-wuu,zh-xiang,";
					if(mIANALangauges.indexOf("," + val.toLowerCase() + ",") >= 0) {
						res = true;
					}
					return res;
				} else {
					var bloqueCodes = ",frl,exg,sp,ruq-jm,";
					if(bloqueCodes.indexOf("," + val.toLowerCase() + ",") >= 0) {
						return false;
					}
				}
				return true;
			},
			//以ISO格式验证持续时间
			CheckIsoIntervalFormat(dateS) {
				if(dateS.indexOf(".") > 0 && dateS.indexOf("S") > 0) {
					dateS = dateS.substring(0, dateS.indexOf(".")) + "S";
				}
				var indT = dateS.indexOf("T");
				if(indT > 0 && indT == (dateS.length - 1)) {
					return false;
				}
				var resm = dateS.match(/^P(\d+Y)?(\d+M)?(\d+D)?(T(\d+H)?(\d+M)?(\d+S)?)?$/);
				if(resm == null) {
					this.infoLMSSpe = "Invalid time interval format";
				}
				return(resm != null);
			},
			//对目标进行计算
			ApplyResultInSequence() {
				if(!this.depiste) {
					return;
				}

				var objectifPrimairePris = false;
				// TODO : traiter les objectifs autres que principal
				var objCount = parseInt(this.objapi.GetValue("cmi.objectives._count"));
				for(var cptObj = 0; cptObj < objCount; cptObj++) {
					if(this.GetRawValue("cmi.objectives." + cptObj + ".mos.modifObj") == "oui") {
						this.SetRawValue("cmi.objectives." + cptObj + ".mos.modifObj", "");
						//                if (GetRawValue("cmi.objectives." + cptObj + ".id") == this.objectifCible) {
						//                    objectifPrimairePris = true;
						//                }
					}
				}

				if(!objectifPrimairePris) {
					if(this.GetRawValue("mos.modifObjPrinc") == "oui") {
						this.SetRawValue("mos.modifObjPrinc", "");
						var objectifPrincipalIndex = this.GetRawValue("ro.objectives._primary.index");
						if(objectifPrincipalIndex != null) {
							var resSet = this.objapi.GetValue("cmi.success_status");
							if(resSet == "unknown") {
								this.objapi.SetValue("cmi.objectives." + objectifPrincipalIndex + ".completion_status", "incomplete");
							} else {
								this.objapi.SetValue("cmi.objectives." + objectifPrincipalIndex + ".completion_status", "completed");
							}
							this.objapi.SetValue("cmi.objectives." + objectifPrincipalIndex + ".success_status", resSet);
							resSet = this.objapi.GetValue("cmi.score.scaled");
							if(resSet != "") {
								this.objapi.SetValue("cmi.objectives." + objectifPrincipalIndex + ".score.scaled", resSet);
							}
						}
					}
				}

				this.errorCode = "0";
			},
			//检查保存环境的情况
			CanSaveAttempt(localModeTentative) {
				if(localModeTentative == "aucune") return false;
				var tmpSuccessStatus = this.GetRawValueOrDefault("cmi.success_status", "");
				if(this.GetRawValueOrDefault("cmi.score.scaled", "") == "" || ((tmpSuccessStatus != "failed") && (tmpSuccessStatus != "passed"))) {
					var result = false;
					for(let cptObj = 0; cptObj < this.GetRawValueOrDefault("cmi.objectives._count", 0); cptObj++) {
						if(this.GetRawValueOrDefault("cmi.objectives." + cptObj + ".success_status", "") == "unknown") result = true;
					}
					if(result == false) return false;
				}
				if(this.GetRawValueOrDefault("cmi.completion_status", "") == "completed") return true;
				if(this.GetRawValueOrDefault("cmi.exit", "") == "suspend") return false;
				return true;
			},
			//以UTC格式化的时间戳
			GetServerDate(mode) {
				var resu = '';
				var dateJS = new Date();
				var moisServeur = "0";
				var jourServeur = "0";
				var anneeServeur = dateJS.getUTCFullYear();
				if(dateJS.getUTCMonth() + 1 < 10) {
					moisServeur = moisServeur + (dateJS.getUTCMonth() + 1).toString();
				} else {
					moisServeur = dateJS.getUTCMonth() + 1;
				}
				if(dateJS.getUTCDate() < 10) {
					jourServeur = jourServeur + (dateJS.getUTCDate()).toString();
				} else {
					jourServeur = dateJS.getUTCDate();
				}
				if(mode == 'yyyymmjj') {
					resu = anneeServeur + "-" + moisServeur + "-" + jourServeur;
				} else {
					var heureServeur = "0";
					var minutesServeur = "0";
					var secondesServeur = "0";
					if(dateJS.getUTCHours() < 10) {
						heureServeur += (dateJS.getUTCHours()).toString();
					} else {
						heureServeur = dateJS.getUTCHours();
					}
					if(dateJS.getUTCMinutes() < 10) {
						minutesServeur += (dateJS.getUTCMinutes()).toString();
					} else {
						minutesServeur = dateJS.getUTCMinutes();
					}
					if(dateJS.getUTCSeconds() < 10) {
						secondesServeur += (dateJS.getUTCSeconds()).toString();
					} else {
						secondesServeur = dateJS.getUTCSeconds();
					}
					resu = anneeServeur + "-" + moisServeur + "-" + jourServeur + "T" + heureServeur + ":" + minutesServeur + ":" + secondesServeur;
				}
				if(mode == 'milli') {
					var milli = dateJS.getUTCMilliseconds();
					if(milli < 10) {
						milli = "0" + milli.toString();
					} else if(milli > 99) {
						milli = milli.toString().substring(0, 2);
					}
					resu += "." + milli;
				}
				return resu;
			},
			StringStartsWith(string, pattern) {
				return(string.indexOf(pattern) == 0);
			},
			initFunc() {
				var _this = this;
				window.API_1484_11 = this.objapi = (function() {
					let obj = {};
					_this.oldId = _this.resId;
					
					//初始化
					obj.Initialize = function(strParametre) {
						_this.debug('Initialize', false);
						// Aucun paramètre autorisé
						if(strParametre != "") {
							_this.errorCode = "201";
							return "false";
						}
						if(_this.apiData == null) {
							_this.errorCode = "201";
							return "false";
						}
						// Deja initialise
						if(_this.etatCommunication == "enCours") {
							_this.errorCode = "103";
							return "false";
						}

						_this.etatCommunication = "";
						_this.errorCode = "0";
						_this.cibleChoix = "";
						_this.derniereAffectation = "";
						_this.valeurDerniereAffectation = "";
						_this.dateTentativeEnvoi = "";

						// On récupère l'id du SCO tel que fourni par le LMS Redirector, de sorte à ce que SetRawValue affecte correctement les valeurs
						_this.playerScoId = _this.GetRawValueOrDefault("ro.sco_id", "");

						// Dans tous les cas, puisque l'on est en navigation mono-SCO, on réinitialise les informations de navigation
						this.SetValue("adl.nav.request", "_none_");

						// On part d'un ensemble de valeurs obligatoirement existant
						if(_this.LocalDbHasAtLeastOnePrefix("cmi.")) {
							// On détermine le mode d'entrée (standard ou reprise) en fonction de la dernière sortie
							// Attention on n'utilise pas GetValue standard car la lecture de cette valeur est interdite via l'API
							var valExit = _this.GetRawValueOrDefault("cmi.exit", "");
							_this.SetRawValue("cmi.exit", null);
							_this.SetRawValue("cmi.entry", null);
							if(valExit == "suspend") {
								// Dans ce cas un ensemble de valeur existe déjà (de la dernière session suspendue)
								_this.SetRawValue("cmi.entry", "resume");
								//                debug('Tentative complétée : ' + GetRawValueOrDefault("mos.timestampTentative", "666"), true);
							} else {
								// On part de zéro, on efface
								_this.LocalDbDeleteAllPrefix("cmi.");
								_this.LocalDbDeleteAllPrefix("adl.data.");
								// On efface également les valeurs spécifique à l'implémentation MindOnSite pour le LMS local
								_this.LocalDbDeleteAllPrefix("mos.");
							}
						}
						if(!_this.LocalDbHasAtLeastOnePrefix("cmi.")) {
							// Valeurs par défaut d'une nouvelle collection
							if(_this.GetRawValueOrDefault("cmi.learner_preference.audio_level", "") == "") this.SetValue("cmi.learner_preference.audio_level", "1");
							// Duplication dans le namespace cmi. de certaines valeurs qui en RW
							_this.LocalDbDuplicatePrefixedValues("ro.objectives.", "cmi.objectives.");
							// Pareil pour les données partagées
							_this.LocalDbDuplicatePrefixedValues("ro.data.", "adl.data.");
							// On initialise une nouvelle tentative
							var timestampTentative = new String(Math.round(new Date().getTime() / 1000.0))
							_this.SetRawValue("mos.timestampTentative", timestampTentative)
							//            debug('Nouvelle tentative : ' + timestampTentative, true);
						}

						// Membre "tracked" dans le manifest
						_this.depiste = true;
						if(_this.GetRawValue("ro.tracked") == "false") {
							_this.depiste = false;
						}

						// Récupération de l'id de l'objectif principal
						_this.objectifCible = _this.GetRawValueOrDefault("ro.objectives._primary.id", "");

						// initialiser les valeurs de l'objetcif principal
						var objectifPrincipalIndex = _this.GetRawValue("ro.objectives._primary.index");
						if(objectifPrincipalIndex != null) {
							var tmp;
							// Score
							tmp = _this.GetRawValue("cmi.objectives." + objectifPrincipalIndex + ".score.scaled");
							if(tmp != null) _this.SetRawValue("cmi.score.scaled", tmp);
							tmp = _this.GetRawValue("cmi.objectives." + objectifPrincipalIndex + ".score.min");
							if(tmp != null) _this.SetRawValue("cmi.score.min", tmp);
							tmp = _this.GetRawValue("cmi.objectives." + objectifPrincipalIndex + ".score.max");
							if(tmp != null) _this.SetRawValue("cmi.score.max", tmp);
							tmp = _this.GetRawValue("cmi.objectives." + objectifPrincipalIndex + ".score.raw");
							if(tmp != null) _this.SetRawValue("cmi.score.raw", tmp);

							// Réussite
							tmp = _this.GetRawValue("cmi.objectives." + objectifPrincipalIndex + ".success_status");
							if(tmp != null) _this.SetRawValue("cmi.success_status", tmp);
						}

						// Initialiser les variables SSP/ADL.DATA si on n'est pas en resume
						if(_this.GetRawValueOrDefault("cmi.entry", "") != "resume") {
							_this.LocalDbDuplicatePrefixedValues("ro.data.", "adl.data.");
						}

						// Initialiser les variables SSP statiques
						// Ou pas, en l'état l'API ne gère pas les SSP (ne fait pas partie de SCORM)

						// Variables de trace
						_this.modifications = false;
						_this.changementEtatCompletion = false;
						_this.changementEtatObjectif = false;
						_this.changementEtatSession = false;
						_this.changementObjectifSecondaire = false;

						// Initialisation terminée
						_this.errorCode = "0";
						_this.etatCommunication = "enCours";
						_this.oldId = _this.id;
						return "true";
					};
					//结束
					obj.Terminate = function(strParametre) {
						_this.debug('Terminate', false);
						_this.infoLMSSpe = "";
						if(_this.etatCommunication == "pasInitialise") {
							_this.errorCode = "112";
							return "false";
						}
						if(_this.etatCommunication == "termine") {
							_this.errorCode = "113";
							return "false";
						}
						if(strParametre != "") {
							_this.errorCode = "201";
							return "false";
						}
						_this.errorCode = "0";
						var sequenceProposee = "";
						var valExit = _this.GetRawValueOrDefault("cmi.exit", "");
						if(!_this.scoEnAttente) {
							var valReq = this.GetValue("adl.nav.request");
							if(valReq != "_none_") {
								sequenceProposee = valReq;
								if(sequenceProposee == "suspendAll" && (valExit == "" || valExit == "normal")) {
									this.SetValue("cmi.exit", "suspend");
									valExit = _this.GetRawValueOrDefault("cmi.exit", "");
								}
							}
						}
						if(valExit == "time-out" || valExit == "logout") {
							sequenceProposee = "exitAll";
							_this.scoEnAttente = false;
						}
						if(sequenceProposee == "abandonAll" || sequenceProposee == "abandon") {
							_this.abandonEnCours = true;
						}
						if(valExit == "suspend") {
							_this.modifications = true;
							_this.SetRawValue("adl.nav.request", "");
						}

						// 地方LMS（IPAD）的时间积累管理
						// 恢复之前的值并累计
						var sessionTime = _this.GetRawValue("cmi.session_time");
						if(sessionTime != null) {
							// Reprise de la valeur précédente et cumul
							var ipadElapsed = _this.GetRawValueOrDefault("mos.ipad_time_elapsed", "PT0S");
							ipadElapsed = _this.GetIsoIntervalSum(ipadElapsed, sessionTime);
							_this.SetRawValue("mos.ipad_time_elapsed", ipadElapsed);
						}

						// 完成API（最终提交和更改状态）
						_this.SetRawValue("mos.local_api_version", "201306261740");
						this.Commit("");
						_this.etatCommunication = "termine";
						_this.errorCode = "0";
						if(_this.scoEnAttente) {
							// permet au prochine SCO de demarrer——允许SCO开始
							_this.etatCommunication = "pasInitialise";
							_this.scoEnAttente = false;
						} else if(sequenceProposee != "") {
							_this.etatCommunication = "pasInitialise"; // ferme connexion
						}

						return "true";
					};
					obj.GetValue = function(key) {

						//						if (_this.GetRawValue(key) == null) _this.SetRawValue("missing." + key, "_just_asked_");
						_this.debug('GetValue(' + key + ')', false);
						_this.infoLMSSpe = "";

						if(_this.etatCommunication == "pasInitialise") {
							_this.errorCode = "122";
							return "";
						}
						if(_this.etatCommunication == "termine") {
							_this.errorCode = "123";
							return "";
						}
						if(key == "") {
							_this.errorCode = "301";
							return "";
						}
						// On ne traite qu'une partie des espaces de nom——只处理部分命名空间
						if(key.indexOf("cmi.") != 0 && key.indexOf("adl.") != 0 && key.indexOf("ssp.") != 0) {
							_this.errorCode = "401";
							return "";
						}
						// 用于快速交换ro.*值*
						var shortKey = key;
						if(key.indexOf("ssp.") != 0) {
							shortKey = key.substring(4);
						}
						if(key == "cmi.comments_from_learner" || key == "cmi.learner_preference" || key == "cmi.interactions" || key == "cmi.comments_from_lms" || key == "cmi.objectives" || key == "adl.data") {
							_this.errorCode = "401";
							return "";
						}
						if(key == _this.derniereAffectation) {
							return _this.valeurDerniereAffectation;
						}

						// Réinitialisation du code d'erreur——重置错误代码
						_this.errorCode = "0";
						// Variables déduites ou constantes
						if(key == "cmi._version") return "1.0";
						if(key == "cmi.comments_from_learner._children") {
							return "comment,location,timestamp";
						}
						if(key == "cmi.comments_from_learner._count") return _this.GetRawValueOrDefault(key, 0);
						if(key == "cmi.comments_from_lms._children") {
							return "comment,location,timestamp";
						}
						if(key == "cmi.comments_from_lms._count") return _this.GetRawValueOrDefault("ro." + shortKey, 0);
						if(key == "cmi.completion_status") return _this.ComputeCompletionStatus(_this.GetRawValueOrDefault("cmi.completion_status", ""));
						if(key == "cmi.success_status") return _this.ComputeSuccessStatus(_this.GetRawValueOrDefault("cmi.success_status", ""));
						if(key == "cmi.completion_threshold") return _this.GetRawValueOrError("ro." + shortKey);
						if(key == "cmi.credit") return "credit";
						if(key == "cmi.entry") return _this.GetRawValueOrDefault(key, "ab-initio");
						if(key == "cmi.exit" || key == "cmi.session_time") {
							_this.errorCode = "405";
							return "";
						}
						if(key == "cmi.interactions._children") return "id,type,objectives,timestamp,correct_responses,weighting,learner_response,result,latency,description";
						if(key == "cmi.interactions._count") return _this.GetRawValueOrDefault(key, 0);
						if(_this.StringStartsWith(key, "cmi.interactions.") && _this.StringEndsWith(key, ".objectives._count")) return _this.GetRawValueOrDefault(key, 0);
						if(_this.StringStartsWith(key, "cmi.interactions.") && _this.StringEndsWith(key, ".correct_responses._count")) return _this.GetRawValueOrDefault(key, 0);
						if(key == "cmi.launch_data") return _this.GetRawValueOrError("ro." + shortKey);
						if(key == "cmi.learner_id") return _this.GetRawValueOrDefault("ro." + shortKey, "mosplayeripaduserid");
						if(key == "cmi.learner_name") return _this.GetRawValueOrDefault("ro." + shortKey, "MOS Player iPad User");
						if(key == "cmi.learner_preference._children") return "audio_level,language,delivery_speed,audio_captioning";
						if(key == "cmi.max_time_allowed") return _this.GetRawValueOrError("ro." + shortKey);
						if(key == "cmi.mode") {
							if(!_this.depiste) return "browse";
							return _this.GetRawValueOrDefault("ro." + shortKey, "normal");
						}
						if(key == "cmi.objectives._children") return "id,completion_status,success_status,description,progress_measure,score";
						if(key == "cmi.objectives._count") return _this.GetRawValueOrDefault(key, 0);
						if(key == "cmi.location") return _this.GetRawValueOrError(key);
						if(key == "cmi.progress_measure") return _this.GetRawValueOrError(key);
						// L'analyse de l'objectif principal est faite depuis MOS Chorus via le WS
						//主要目标分析是通过WS从MOS Chorus完成的
						if(key == "cmi.scaled_passing_score") return _this.GetRawValueOrError("ro." + shortKey);
						if(key.indexOf("cmi.score._children") == 0) return "scaled,raw,min,max";
						if(key.indexOf("cmi.score.") == 0) return _this.GetRawValueOrError(key);
						if(key == "cmi.suspend_data") return _this.GetRawValueOrError(key);
						if(key == "cmi.time_limit_action") return _this.GetRawValueOrError("ro." + shortKey);
						if(key == "cmi.total_time") return _this.GetRawValueOrDefault("ro." + shortKey, "PT0S");
						//TODO : Pas de navigation en monosco, mais peut-être dans le futur
						if(key.indexOf("adl.nav.request") == 0) {
							if(key == "adl.nav.request") return _this.GetRawValueOrDefault("adl.nav.request", "_none_");
							if(key.indexOf("adl.nav.request_valid.choice") == 0) {
								var inf = key.indexOf("{target=");
								var sup = key.indexOf("}");
								if(inf > 0 && inf < sup) {console.log('cc')} else {
									_this.errorCode = "301";
									return "";
								}
							}
							if(key.indexOf("adl.nav.request_valid.jump") == 0) {
								// var inf = key.indexOf("{target=");
								// var sup = key.indexOf("}");
								if(inf > 0 && inf < sup) {
									// window.planLMS.activiteSpecifieParChoixPreemptif = key.substring(inf + 8, sup);
									// return window.haut.NAVIG_requete("jump", true);
								} else {
									_this.errorCode = "301";
									return "";
								}
							}
							_this.errorCode = "301";
							return "";
						}
						// Gestion du stockage de données locales——本地数据存储管理
						if(key == "adl.data._count") return _this.GetRawValueOrDefault(key, 0);
						// En l'état pas de gestion du SSP
						if(key.indexOf("ssp.") == 0) {
							_this.errorCode = "401";
							return "";
						}
						// Par contre adl.data fait partie du standard
						if(key == "adl.data._children") return "id,store";
						// Seules les enfants officiels sont lisible par le SCO
						if(key.indexOf("adl.data.") == 0) {
							if((!_this.StringEndsWith(key, ".id")) && (!_this.StringEndsWith(key, ".store"))) {
								_this.errorCode = "301";
								return "";
							}
							// TODO : virer le debug
							//debug('GetValue(' + key + '):' + GetRawValue(key), true);
						}
						if(key.indexOf("cmi.objectives.") == 0) {
							// TODO : 打开调试
							//							_this.debug('GetValue(' + key + '):' + _this.GetRawValue(key), true);
						}

						// Recherche par défaut——默认搜索
						var result = _this.GetRawValue(key);
						if(result == null) {
							_this.errorCode = "201";
							return "";
						}
						return result;

					};
					obj.SetValue = function(key, value) {

						_this.debug('SetValue(' + key + ',' + value + ')', false);
						_this.infoLMSSpe = "";
						_this.derniereAffectation = "";
						if(_this.etatCommunication == "pasInitialise") {
							_this.errorCode = "132";
							return "false";
						}
						if(_this.etatCommunication == "termine") {
							_this.errorCode = "133";
							return "false";
						}

						if(key == "") {
							_this.errorCode = "351";
							return "false";
						}
						if(key.indexOf("cmi.") != 0 && key.indexOf("adl.") != 0 && key.indexOf("ssp.") != 0) {
							_this.errorCode = "401";
							return "false";
						}
						_this.errorCode = "0";
						_this.modifications = true;
						if(key == "cmi.interactions" || key == "cmi.learner_preference" || key == "cmi.objectives" || key == "cmi.comments_from_learner" || key == "cmi.comments_from_lms") {
							_this.errorCode = "401";
							return "false";
						}
						if(key == "cmi._version" || key.indexOf("._children") > 0 || key.indexOf("._count") > 0 || key.indexOf("cmi.comments_from_lms") == 0 || key == "cmi.completion_threshold" ||
							key == "cmi.credit" || key == "cmi.entry" || key == "cmi.time_limit_action" || key == "cmi.launch_data" || key == "cmi.scaled_passing_score" || key == "cmi.total_time") {
							_this.errorCode = "404";
							return "false";
						}
						if(key == "cmi.learner_id" || key == "cmi.learner_name" || key == "cmi.max_time_allowed" || key == "cmi.mode" || key.indexOf("adl.nav.request_valid") == 0) {
							_this.errorCode = "404";
							return "false";
						}

						// Collection cmi.comments_from_learner.*
						if(key.indexOf("cmi.comments_from_learner.") == 0) {
							if(!_this.LocalDbHasAtLeastOnePrefix(_this.GetKeyUntilNthRoot(key, 3))) {
								let cptObj = this.GetValue("cmi.comments_from_learner._count");
								cptObj++; // Interprétation numérique
								_this.SetRawValue("cmi.comments_from_learner._count", cptObj);
							}
						}
						// Completion status
						if(key == "cmi.completion_status") {
							var oldValue = _this.GetRawValueOrDefault(key, "");
							var result = _this.SetRawValueFromVocabulary(key, value, "completed,incomplete,not attempted,unknown");
							if(result == "false") return "false";
							_this.ComputeCompletionStatus(oldValue);
							// TODO : Communication avec le player?
							//            if (value!= "") { _this.activite.setAttribute("affecteEtatCompletion", "1"); }
							_this.errorCode = "0";
							return "true";
						}
						// Exit
						if(key == "cmi.exit") {
							result = _this.SetRawValueFromVocabulary(key, value, "time-out,suspend,logout,,normal");
							if((result == "true") && (value == "time-out" || value == "logout")) {
								_this.SetRawValue("adl.nav.request", "_none_");
							}
							return result;
						}
						// Collection cmi.interactions.*
						if(key.indexOf("cmi.interactions.") == 0) {
							// Nouvelle interaction ?
							var intRoot = _this.GetKeyUntilNthRoot(key, 3);
							var int4thElement = _this.GetKeyNthRoot(key, 3);
							// Vérif des enfants possibles
							let lespossibles = ",id,type,timestamp,objectives,correct_responses,weighting,learner_response,result,latency,description,pattern,";
							if(lespossibles.indexOf("," + int4thElement + ",") < 0) {
								_this.infoLMSSpe = "Invalid argument";
								_this.errorCode = "401";
								return "false";
							}
							// Vérif de l'existance du noeud et du contexte de création si besoin
							_this.changementInteraction = true;
							if(!_this.LocalDbHasAtLeastOnePrefix(intRoot)) {
								// 4e élément
								if(int4thElement == "id") {
									/* eslint-disable */
								 	let cptInt = this.GetValue("cmi.interactions._count");
									cptInt++; // Interprétation numérique
									_this.SetRawValue("cmi.interactions._count", cptInt);
								} else {
									// Incrément illégitime
									_this.errorCode = "351";
									return "false";
								}
							} else {
								// L'interaction a déjà un ID
								// Cas des deux sous-collections
								if((int4thElement == "objectives") || (int4thElement == "correct_responses")) {
									let cptInt = this.GetValue(intRoot + int4thElement + "._count");
									cptInt++; // Interprétation numérique
									_this.SetRawValue(intRoot + int4thElement + "._count", cptInt);
								}
								if(int4thElement == "type") {
									// Ne peut être affecté à du vide
									if(value == "") {
										_this.errorCode = "408";
										return "false";
									} else {
										// Valeurs standards
										let lestypes = ",true-false,choice,fill-in,long-fill-in,matching,performance,sequencing,likert,numeric,other,";
										if(lestypes.indexOf("," + value + ",") < 0) {
											_this.errorCode = "406";
											return "false";
										}
									}
								}
								if(int4thElement == "result") {
									// Le résultat peut être un numérique ou un état basé sur des valeurs standard
									if(value == "") {
										_this.errorCode = "406";
										return "false";
									}
									let lestypes = ",correct,incorrect,neutral,unanticipated,";
									if(lestypes.indexOf("," + value + ",") < 0) {
										if(isNaN(Number(value))) {
											_this.errorCode = "406";
											return "false";
										}
									}
								}
							}
						}
						if(key == "cmi.learner_preference.audio_level") {
							_this.errorCode = _this.CheckBoundedReal(value, 0, "*");
							if(_this.errorCode == "0") {
								_this.SetRawValue(key, value);
								return "true";
							}
							return "false";
						}
						if(key == "cmi.learner_preference.language") {
							if(!_this.CheckLanguageCodeFormat(value)) {
								_this.errorCode = "406";
								return "false";
							}
							_this.SetRawValue(key, value);
							return "true";
						}
						if(key == "cmi.learner_preference.delivery_speed") {
							_this.errorCode = _this.CheckBoundedReal(value, 0, "*");
							if(_this.errorCode == "0") {
								_this.SetRawValue(key, value);
								return "true";
							}
							return "false";
						}
						if(key == "cmi.learner_preference.audio_captioning") {
							return _this.SetRawValueFromVocabulary(key, value, "-1,0,1");
						}
						// cmi.location
						// Collection cmi.objectives.*
						if(key.indexOf("cmi.objectives.") == 0) {
							// TODO : virer le debug
							_this.debug('SetValue(' + key + ',' + value + ')', false);
							// Nouvelle interaction ?
							var objRoot = _this.GetKeyUntilNthRoot(key, 3);
							var obj4thElement = _this.GetKeyNthRoot(key, 3);
							// Vérif des enfants possibles
							var lespossibles = ",id,score,success_status,completion_status,progress_measure,description,";
							if(lespossibles.indexOf("," + obj4thElement + ",") < 0) {
								_this.infoLMSSpe = "Invalid argument";
								_this.errorCode = "401";
								return "false";
							}
							// Vérif de l'existance du noeud et du contexte de création si besoin-如有必要，检查节点是否存在以及创建上下文
							if(!_this.LocalDbHasAtLeastOnePrefix(objRoot)) {
								// 4e élément
								if(obj4thElement == "id") {
									var cptObj = this.GetValue("cmi.objectives._count");
									cptObj++; // Interprétation numérique
									_this.SetRawValue("cmi.objectives._count", cptObj);
								} else {
									// Incrément illégitime
									_this.errorCode = "351";
									return "false";
								}
							} else {
								if(obj4thElement == "score") {
									var scoreType = _this.GetKeyNthRoot(key, 4);
									// On vérifie que le score scaled est bien dans l'intervalle accepté
									if(scoreType == "scaled") {
										_this.errorCode = _this.CheckBoundedReal(value, -1, 1);
										if(_this.errorCode != "0") {
											return "false";
										}
										oldValue = _this.GetRawValueOrDefault(key, -2);
										if(value != oldValue) {
											// On garde la trace que cet objectif a été altéré
											_this.changementObjectifSecondaire = true;
											_this.SetRawValue(objRoot + "mos.modifObj", "oui");
										}
									} else {
										lespossibles = ",min,max,raw,";
										if(lespossibles.indexOf("," + scoreType + ",") < 0) {
											_this.infoLMSSpe = "Invalid argument";
											_this.errorCode = "401";
											return "false";
										}
										if(isNaN(Number(value))) {
											_this.errorCode = "406";
											return "false";
										}
									}
								}
								if(obj4thElement == "success_status") {
									oldValue = _this.GetRawValueOrDefault(key, "");
									result = _this.SetRawValueFromVocabulary(key, value, "passed,failed,unknown");
									if(result == "false") return "false";
									if(value != oldValue) {
										// On garde la trace que cet objectif a été altéré
										_this.changementObjectifSecondaire = true;
										_this.SetRawValue(objRoot + "mos.modifObj", "oui");
									}
									// TODO : Gestion du objectiveSetByContent
								}
								if(obj4thElement == "completion_status") {
									oldValue = _this.GetRawValueOrDefault(key, "");
									result = _this.SetRawValueFromVocabulary(key, value, "completed,incomplete,not attempted,unknown");
									if(result == "false") return "false";
									if(value != oldValue) {
										// On garde la trace que cet objectif a été altéré
										_this.changementObjectifSecondaire = true;
										_this.SetRawValue(objRoot + "mos.modifObj", "oui");
									}
								}
								if(obj4thElement == "progress_measure") {
									_this.errorCode = _this.CheckBoundedReal(value, 0, 1);
									if(_this.errorCode != "0") {
										return "false";
									}
								}
							}
						}
						if(key == "cmi.progress_measure") {
							_this.errorCode = _this.CheckBoundedReal(value, 0, 1);
							if(_this.errorCode != "0") {
								return "false";
							}
							_this.SetRawValue("cmi.progress_measure", value);
							_this.ComputeCompletionStatus(_this.GetRawValueOrDefault("cmi.completion_status", ""));
							_this.errorCode = "0";
							return "true";
						}
						if(key.indexOf("cmi.score.") == 0) {
							scoreType = _this.GetKeyNthRoot(key, 2);
							if(scoreType == "scaled") {
								_this.errorCode = _this.CheckBoundedReal(value, -1, 1);
								if(_this.errorCode != "0") {
									return "false";
								}
								oldValue = _this.GetRawValueOrDefault(key, "");
								if(_this.depiste) {
									if(value != oldValue) {
										// On garde la trace que cet objectif a été altéré
										_this.changementScore = true;
										_this.SetRawValue("mos.modifObjPrinc", "oui");
										_this.ComputeSuccessStatus(_this.GetRawValueOrDefault("cmi.success_status", ""));
									}
									// Le score transmis à l'objectif ne peut excéder une précision au delà de 9 digits
									var scoreTrans = String(value);
									if(scoreTrans.length > 9) {
										scoreTrans = scoreTrans.substring(0, 9);
									}
									// TODO : Implémenter une véritable gestion des objectifs
									var objectifPrincipalIndex = _this.GetRawValue("ro.objectives._primary.index");
									if(objectifPrincipalIndex != null) {
										_this.SetRawValue("cmi.objectives." + objectifPrincipalIndex + ".score.scaled", scoreTrans);
									}
								}
							} else {
								lespossibles = ",min,max,raw,";
								if(lespossibles.indexOf("," + scoreType + ",") < 0) {
									_this.infoLMSSpe = "Invalid argument";
									_this.errorCode = "401";
									return "false";
								}
								if(isNaN(Number(value))) {
									_this.errorCode = "406";
									return "false";
								}
							}
						}
						if(key == "cmi.session_time") {
							//            debug('SetValue(' + key + ',' + value + ')', true);
							var sansZero = String(value);
							var oReg = new RegExp("([A-Z])0([0-9]+)([A-Z])", "g");
							sansZero = sansZero.replace(oReg, "$1$2$3");
							sansZero = sansZero.replace(oReg, "$1$2$3");
							sansZero = sansZero.replace(oReg, "$1$2$3");
							if(!_this.CheckIsoIntervalFormat(sansZero)) {
								_this.errorCode = "406";
								return "false";
							}
						}
						if(key == "cmi.success_status") {
							oldValue = _this.GetRawValueOrDefault(key, "");
							result = _this.SetRawValueFromVocabulary(key, value, "passed,failed,unknown");
							if(result == "false") {
								return "false";
							}
							_this.ComputeSuccessStatus(oldValue);
							//            if (strValeur != "") { _this.activite.setAttribute("affecteEtatSucces", "1"); }
							_this.errorCode = "0";
							return "true";
						}
						// cmi.suspend_data
						if(key == "adl.nav.request") {
							if(value.indexOf("}choice") > 0 || value.indexOf("}jump") > 0) {
								var inf = value.indexOf("{target=");
								var sup = value.indexOf("}");
								if(inf == 0 && sup > inf) {
									var cible = value.substring(inf + 8, sup);
									if(cible.length > 0) {
										_this.SetRawValue("adl.nav.request", value);
										_this.cibleChoix = cible;
										return "true";
									}
								}
								_this.errorCode = "406";
								return "false";
							} else {
								_this.cibleChoix = "";
								if(value == "suspendAll") {
									_this.SetRawValue("cmi.exit", "");
								}
								return _this.SetRawValueFromVocabulary("adl.nav.request", value, "continue,previous,choice,jump,exit,exitAll,abandon,abandonAll,suspendAll,_none_");
							}
						}
						// Pas de support du SSP
						if(key.indexOf("ssp.") == 0) {
							_this.errorCode = "401";
							return "false";
						}
						// Par contre support de adl.data conformément à SCORM 2004.4
						if(key.indexOf("adl.data.") == 0) {
							// TODO : virer le debug
							//debug('SetValue(' + key + ',' + value + ')', true);
							if(_this.StringEndsWith(key, ".id")) {
								_this.errorCode = "404";
								return "false";
							}
							if(_this.StringEndsWith(key, ".store")) {
								var dataRoot = _this.GetKeyUntilNthRoot(key, 3);
								var data4thElement = _this.GetKeyNthRoot(key, 3);
								console.log('data4thElement',data4thElement)
								if(_this.LocalDbHasAtLeastOnePrefix(dataRoot)) {
									if(value.length > 64000) {
										_this.errorCode = "406";
										return "false";
									}
									if(_this.GetRawValueOrDefault(dataRoot + "writeSharedData", "") == "false") {
										_this.errorCode = "404";
										return "false";
									}
									_this.SetRawValue(dataRoot + "modif", "oui");
									_this.modificationAlloc = true;
									_this.persisteSSP = true;
									value = new String(value);
									_this.SetRawValue(key, value);
									_this.derniereAffectation = key;
									_this.valeurDerniereAffectation = value;
									return "true";
								} else {
									_this.errorCode = "351";
									return "false";
								}
							}
						}

						// Valeur de retour obligatoire
						_this.SetRawValue(key, value);
						_this.errorCode = "0";
						return "true";

					};

					obj.Commit = function(strParametre) {
						console.log(strParametre)
						_this.debug('Commit', false);
						//						if(_this.etatCommunication == "pasInitialise") {
						//							_this.errorCode = "142";
						//							return "false";
						//						}
						//						if(_this.etatCommunication == "termine") {
						//							_this.errorCode = "143";
						//							return "false";
						//						}
						//						if(strParametre != "") {
						//							_this.errorCode = "201";
						//							return "false";
						//						}
						//						if(_this.abandonEnCours) return "false";
						_this.errorCode = "0";

						// Petite tambouille interne si des variables ont été modifiées
						if(_this.modifications) {
							// TODO : LMS_appliquerResultatDansSeq——应用结果
							_this.ApplyResultInSequence();
							_this.errorCode = "0";
							// TODO : Vérifier d'où vient la variable modeTentative——检查模型变量的来源

							if(_this.CanSaveAttempt('')) {
								var existeInteractions = (parseInt(this.GetValue("cmi.interactions._count")) > 0);
								var nbObjectifs = this.GetValue("cmi.objectives._count");
								var existeObjectifs = (nbObjectifs > 1);
								var tentaObjSansInter = false;
								if(existeObjectifs && this.GetValue("cmi.completion_status") == "completed") tentaObjSansInter = true;
								if(existeInteractions || tentaObjSansInter) {
									// Lecture en raw car interdit par SCORM pour le parcours——SCORM禁止阅读
									var tempsSession = _this.GetRawValueOrDefault("cmi.session_time", "PT0S");
									var tempsPasse0 = 0;
									if(tempsSession != "") {
										tempsPasse0 = Math.round(_this.ConvertIsoIntervalToMs(tempsSession) / 1000);
									}
									var memeTentative = false;
									if(_this.dateTentativeEnvoi != "") {
										// il y at-il eu des changements ?——是否有变化
										if(!_this.changementInteraction && !_this.changementEtatObjectif && !_this.changementScore && !_this.changementObjectifSecondaire) {
											memeTentative = true;
										}
									}
									if(!memeTentative) {
										if(_this.dateTentativeEnvoi == "") {
											_this.dateTentativeEnvoi = _this.GetServerDate('');
										}
										_this.SetRawValue("mos.dateTentative", _this.dateTentativeEnvoi);
										_this.SetRawValue("mos.tempsPasse", tempsPasse0);
									}
									_this.changementInteraction = false;
								}
								if(_this.changementEtatObjectif) {
									_this.changementEtatSession = true;
									// window.planLMS.nettoyerDejaFait(_this.activite);
								}

							}

							_this.changementEtatCompletion = false;
							_this.changementEtatObjectif = false;
							_this.changementObjectifSecondaire = false;
							_this.changementScore = false;
							_this.modificationAlloc = false;
							if(_this.depiste) _this.SetRawValue("mos.dateRemonte", _this.GetServerDate('milli'));
						}
						// _this.updateScoDataCore();
						return "true";
					};

					obj.GetLastError = function() {
						_this.debug('GetLastError()' + _this.errorCode, false);
						return _this.errorCode;
					}
					obj.GetDiagnostic = function(numStr) {
						_this.debug('GetDiagnostic(' + numStr + ') => ' + this.GetErrorString(numStr) + '(' + _this.infoLMSSpe + ')', false);
						return this.GetErrorString(numStr) + "\n" + _this.infoLMSSpe;
					}
					obj.GetErrorString = function(c) {
						var r = "";
						if(c == "0") {
							r = "No Error";
						}
						if(c == "101") {
							r = "General Exception";
						}
						if(c == "102") {
							r = "General Initialization Failure"; //普通初始化失败
						}
						if(c == "103") {
							r = "Already Initialized"; //已初始化
						}
						if(c == "104") {
							r = "Content Instance Terminated"; //内容实例已终止
						}
						if(c == "111") {
							r = "General Termination Failure"; //一般终止失败
						}
						if(c == "112") {
							r = "Termination Before Initialization"; //初始化之前终止
						}
						if(c == "113") {
							r = "Termination After Termination"; //终止后终止
						}
						if(c == "122") {
							r = "Retrieve Data Before Initialization"; //在初始化之前检索数据
						}
						if(c == "123") {
							r = "Retrieve Data After Termination"; //终止后检索数据
						}
						if(c == "132") {
							r = "Store Data Before Initialization"; //初始化前存储数据
						}
						if(c == "133") {
							r = "Store Data After Termination"; //终止后存储数据
						}
						if(c == "142") {
							r = "Commit Before Initialization"; //初始化前提交
						}
						if(c == "143") {
							r = "Commit After Termination"; //终止后提交
						}
						if(c == "201") {
							r = "General Argument Error"; //一般参数错误
						}
						if(c == "301") {
							r = "General Get Failure"; //获取失败
						}
						if(c == "351") {
							r = "General Set Failure"; //设置失败
						}
						if(c == "391") {
							r = "General Commit Failure"; //提交失败
						}
						if(c == "401") {
							r = "Undefined Data Model Element"; //未知的数据模型元素
						}
						if(c == "402") {
							r = "Unimplemented Data Model Element"; //未实现的数据模型元素
						}
						if(c == "403") {
							r = "Data Model Element Value Not Initialized"; //数据模型元素值未初始化
						}
						if(c == "404") {
							r = "Data Model Element Is Read Only"; //数据模型元素为只读
						}
						if(c == "405") {
							r = "Data Model Element Is Write Only"; //数据模型元素为只写
						}
						if(c == "406") {
							r = "Data Model Element Type Mismatch"; //数据模型元素类型不匹配
						}
						if(c == "407") {
							r = "Data Model Element Value Out Of Range"; //数据模型元素值超出范围
						}
						if(c == "408") {
							r = "Data Model Dependency Not Established"; //数据模型依赖性未建立
						}
						return r;
					}
					return obj;
				})();
			}
		},
		created() {},
		updated() {

		},
		beforeMount() {

			//			console.log(this.apiData[1])

		},
		mounted() {}
	}
</script>

<style>

</style>