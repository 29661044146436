<template>
    <div class="examList">
        <exam-list></exam-list>
    </div>
</template>
<script>
import ExamList from '../../../layout/business/examList'
export default {
    name:'examList2',
    data(){
        return {

        }
    },
    components:{
        ExamList
    },
    methods: {
        click() {
            window.open(`${window.location.origin}/map/2d5abaa6-fd11-45f7-b149-8a0bb833adcc`, '_self')
        }
    }
}
</script>
<style lang="stylus" scoped>
.examList
    width 1200px
    margin 0 auto
    /deep/.exam-list .tab .text
        margin-top 24px
</style>