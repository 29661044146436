export function tryTimes(callback, times = 3) {
      let first = true;
      function retry() {
            if (times > 0) {
                  times--;
                  setTimeout(() => {
                        callback(retry);
                  }, first ? 0 : 1000);
                  first = false;
                  return true;
            }
            return false;
      }
      retry();
}

// function call() {
//       return new Promise((resolve, reject) => {
//             setTimeout(() => {
//                   reject();
//             }, 1000)
//       });
// }

// tryTimes((retry) => {
//       console.log('begin call')
//       call().then(() => {
//             console.log('call,  finish')
//       }).catch((error) => {
//             console.log('call error')
//             retry();
//       });

// }, 3);