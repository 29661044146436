<template>
 <div class='examresultslistBox'>
     <el-row :gutter="7">
        <el-col :span="6">
            <div class="examresultslistBox-li">
                <div class="line line1"></div>
                <img src="../../asset/images/dd.png" alt="">
                <div class="examresultslistBox-li-num">
                    {{resultsobj.rightCount}}
                </div>
                <p>
                    答对(道)
                </p>
            </div>
            
        </el-col>
        <el-col :span="6">
            <div class="examresultslistBox-li">
                <div class="line line2"></div>
                <img src="../../asset/images/dc.png" alt="">
                <div class="examresultslistBox-li-num">
                    {{resultsobj.errorCount}}
                </div>
                <p>
                    答错(道)
                </p>
            </div>
        </el-col>
        <el-col :span="6">
            <div class="examresultslistBox-li">
                <div class="line line3"></div>
                <img src="../../asset/images/wd.png" alt="">
                <div class="examresultslistBox-li-num">
                    {{resultsobj.emptyCount}}
                </div>
                <p>
                   未答(道）
                </p>
            </div>
        </el-col>
        <el-col :span="6">
           <div class="examresultslistBox-li">
               <div class="line line4"></div>
                <img src="../../asset/images/df.png" alt="">
                <div class="examresultslistBox-li-num">
                   {{resultsobj.score}} 
                </div>
                <p>
                    得分(分)
                </p>
            </div>
        </el-col>
    </el-row>
    <div class="examresultsBox-buttom" v-if="resultsobj.nowExamCount!==0 && resultsobj.nowExamCount!==resultsobj.examCount">
        <el-button type="primary" @click="Reexamination()">再考一次<span v-if="resultsobj.examCount>0">(还有{{resultsobj.nowExamCount}}次机会)</span></el-button>
    </div>
 </div>
</template>

<script>
export default {
    name:"examresultslist",
    props: {
        resultsobj: {
            type: Object,
            default() {
                return {
                };
            },
        },
    },
    data () {
        return {
        };
    },

    methods: {
        Reexamination(){
              this.$router.push({
                path:'/testPaper',
                query:{
                    userPaperId:this.userPaperId,
                    reexamination: 1
                }
            })
        },
        
    },

    mounted(){},
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/examresults.styl"
</style>