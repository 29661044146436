const aliplaryConverter = {
    "zh-CN": "zh-cn",
    "en-US": "en-us",
    "fr-FR": "fr-fr"
}
const frAliplary = {
    'Volume':'Volume',//音量
    'Muted':'Silence',//静音
    'Curent_Volume':'Volume actuel:',//当前音量：
    'Volume_Mute':'Set to mute',//设置为静音
    'Volume_UnMute':'Set to not mute',//设置为非静音
    'Setting':'Paramètres',//设置
    'Resolution':'Clarté',//清晰度
    'Default':'Par défaut',//默认
    'Quality':'Clarté',//清晰度
    'OD':'Dessin original',//原画
    'FD':'Lisse',//流畅
    'LD':'Marquage',//标清
    'SD':'HD',//高清
    'HD':'Super clair',//超清
    '2K':'2K',//2K
    '4K':'4K',//4K
    'FHD':'Full HD',//全高清
    'XLD':'Très rapide',//极速
    'Speed':'Octave',//倍速
    'Speed_1X_Text':'Normal',//正常
    'Speed_05X_Text':'0.5X',//0.5X
    'Speed_125X_Text':'1.25X',//1.25X
    'Speed_15X_Text':'1.5X',//1.5X
    'Speed_2X_Text':'2X',//2X
    'Quality_Switch_To':'Basculer la netteté vers',//清晰度切换到
    'Auto':'Automatique',//自动
    'Speed_Switch_To':'Changement de vitesse',//倍速切换到
    'CloseSubtitle':'Sous - titrage',//关闭字幕
    'OpenSubtitle':'Sous - titres',//打开字幕
    'Fullscreen':'Plein écran',//全屏
    'ExistFullScreen':"Quitter l'écran complet",//退出全屏
    'Exist_Fullscreen':"Quitter l'écran complet",//退出全屏
    'Play':'Jouer',//播放
    'Pause':'Pause',//暂停
    'Quality_Change_Fail_Switch_Text':'Impossible de jouer, passer à',//不能播放，切换为
    'Quality_Change_Text':'Passage à',//正在为您切换到
    'Refresh_Text':'Rafraîchir',//刷新
    'Error_Decode_Text':'Erreur de décodage',//解码错误
    'Error_Play_Text':'Il y a eu une erreur de lecture',//播放出错啦
    'Error_Offline_Text':"Le réseau n'est pas disponible, assurez - vous",//网络不可用，请确定
    'Error_Retry_Text':'Essayez de quitter retry ou rafraîchir',//请尝试退出重试或刷新
}
export default {
    frAliplary,
    aliplaryConverter
}