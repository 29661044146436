<template>
    <div class="exam-detail-page m-container handPaper">
        <div class="exam-detail clearfix">
            <div class="backButton">
                <span @click="goBack">
                     <i class="back el-icon-arrow-left"></i>
                    {{$t('WDNPC_WEB_RETURN',{text:'返回'})}}</span>
            </div>
            <div class="exam-left">
                <div class="exam-top">
                    <div class="exam-top-left">
                        <div class="exam-top-title">
                            <h1>
                              {{ examDetail.examName }}
                            </h1>
                            <span v-if="examDetail.type && frontendConfig == 'true'">{{$t(ConstData.useType[examDetail.type].text)}}</span>
                        </div>
                        <div class="bottom">
                            <span>{{$t('WDNPC_WEB_ANSWERING_PERSON',{text:'答题人：'})}}{{examDetail.userName }}</span>
                            <span>{{$t('WDNPC_WEB_EXAM_TIME',{text:'考试时间：'})}}{{ examDetail.enterTime }}</span>
                            <span>{{$t('WDNPC_WEB_TIME',{text:'用时：'})}}{{ examDetail.useDuration | timeFormatter }}</span>
                        </div>
                    </div>
                    <div class="exam-top-right" v-if="examDetail.scoreAuth == true && !readOver && examDetail.isReadOver === 1">
                        <div class="time">{{ examDetail.score }}<span>{{$t('WDNPC_WEB_POINTS',{text:'分'})}}</span></div>
                        <div class="titles">
                            <span>{{$t('WDNPC_WEB_TOTAL_SCORE',{text:'总分：'})}}{{ examDetail.totalScore }}</span>
                        </div>
                    </div>
                    <!-- <img  :src="unanswered?img3:examDetail.isPass?img1:img2" alt="" class=""> -->
                    <span class="exam-top-img" v-if="examDetail.scoreAuth == true && !readOver">
                        <img  :src="unanswered?img3:examDetail.isPass?img1:img2" alt="" class="img">
                        <!-- <img class="img" :src="unanswered?img6:examDetail.isPass==0?img1:img2" alt=""> -->
                        <span class="span" v-if="unanswered">
                            {{$t('WDNPC_WEB_NO_ANSWER',{text:'未作答'})}}
                        </span>
                        <span v-else>
                            <span v-if="examDetail.isPass" class="span  jigeColor">
                                {{$t('WDNPC_WEB_JIGE',{text:'及格'})}}
                            </span>
                            <span v-else class="span bujigeColor">
                                {{$t('WDNPC_WEB_BUJIGE',{text:'不及格'})}}
                            </span>
                        </span>
                    </span>
                </div>
                <!-- 违规 -->
                <div class="cheat" v-if="examDetail.cheat == 1">
                    <h3>{{$t('WDNPC_WEB_VIOLATION_DETECTED',{text:'检测到您有违规行为'})}}</h3>
                    <div class="border">
                        <div class="block">
                            <div class="top">
                                <svg-icon icon-class="wiegui" class-name="icon-img"></svg-icon>
                                <span>{{$t('WDNPC_WEB_VIOLATION',{text:'违规'})}}</span>
                            </div>
                            <div class="bottom">
                                <span>{{examDetail.remark}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="scroll">
                    <!-- 实操考试阅卷中 -->
                    <div v-if="readOver">
                        <div class="submitReadOver">
                            <img src="../../../assets/images/common/success.png" alt="">
                            <p>{{$t('WDNPC_WEB_PLEASE_WAIT_PATIENTLY_WHEN_YOU_HAVE_SUBMITTED',{text:'您已提交，阅卷中，请耐心等待'})}} ~</p>
                        </div>
                        <div class="abnormal" v-if="examDetail.isValid == 0">
                            <div class="titles">
                                <img src="../asset/images/ycjl.png" alt="" />
                                {{examDetail.invalidType == 9 ?
                                    $t('WDNPC_WEB_THE_EXAM_HAS_BEEN_COMPLETED_OR_AN',{text:'检测到考试已结束或存在异常，已自动交卷！'})
                                    : $t('WDNPC_WEB_EXCEPTION_RECORD',{text:'异常记录'})
                                }}

                            </div>
                            <p v-if="examDetail.invalidType != 9">{{invalid}}</p>
                        </div>
                    </div>
                    <div v-else>
                        <!-- 成绩有效 -->
                        <template v-if="!examDetail.combination">
                            <div v-if="examDetail.isValid == 1">
                                <!-- 允许查看得分 -->
                                <div class="abnormal-list scroll-list" v-if="examDetail.scoreAuth == true">
                                    <div class="number answer">
                                        <svg-icon icon-class="right" class-name="icon-img"></svg-icon>
                                        <h1 class="num">{{ examDetail.paperType==4?'—':examDetail.rightCount }}</h1>
                                        <p class="titles">{{$t('WDNPC_WEB_WDNPC_WEB_ANSWER_RIGHT_DAO',{text:'答对(道)'})}}</p>
                                    </div>
                                    <div class="number incorrectly">
                                        <svg-icon icon-class="unright" class-name="icon-img"></svg-icon>
                                        <h1 class="num">{{ examDetail.paperType==4?'—':examDetail.errorCount }}</h1>
                                        <p class="titles">{{$t('WDNPC_WEB_WRONG_ANSWER_DAO',{text:'答错(道)'})}}</p>
                                    </div>
                                    <div class="number noAanswer">
                                        <svg-icon icon-class="unanswer" class-name="icon-img"></svg-icon>
                                        <h1 class="num">{{ examDetail.paperType==4?'—':examDetail.emptyCount }}</h1>
                                        <p class="titles">{{$t('WDNPC_WEB_NO_ANSWER_DAO',{text:'未答(道)'})}}</p>
                                    </div>
                                    <div class="number score">
                                        <svg-icon icon-class="totalCount" class-name="icon-img"></svg-icon>
                                        <h1 class="num">{{ examDetail.totalCount }}</h1>
                                        <p class="titles">{{$t('WDNPC_WEB_NUMBER_OF_GENERAL_QUESTIONS',{text:'总题数(道)'})}}</p>
                                    </div>
                                </div>
                                <!-- 不允许查看得分 -->
                                <div class="submitSuccess" v-if="examDetail.scoreAuth == false">
                                    <img src="../../../assets/images/common/success.png" alt="">
                                    <p>{{$t('WDNPC_WEB_YOUR_EXAM_HAS_BEEN_SUBMITTED_THANK_YOU',{text:'您的考试已提交，感谢参与作答'})}} ~</p>
                                </div>
                        </div>
                        </template>
                        <!-- 成绩无效 -->
                        <div class="scroll-abnormal" v-if="examDetail.isValid == 0">
                            <h3>{{$t('WDNPC_WEB_ABNORMAL_BEHAVIOR_DETECTED',{text:'检测到您有异常行为'})}}</h3>
                            <div class="abnormal-con" :class="examDetail.scoreAuth == true ? 'martop48' : ''">
                                <img src="../../../assets/images/common/error.png" alt="">
                                <span>
                                    {{examDetail.invalidType == 9 ? $t('WDNPC_WEB_THE_EXAM_HAS_BEEN_COMPLETED_OR_AN',{text:'检测到考试已结束或存在异常，已自动交卷！'})
                                    : $t('WDNPC_WEB_EXCEPTION_RECORD',{text:'异常记录'})
                                    }}

                                   
                                </span>
                                <p v-if="examDetail.invalidType != 9">{{invalid}}</p>
                            </div>
                            <!-- 允许查看得分 -->
                            <template v-if="!examDetail.combination">
                                <div class="abnormal-list" v-if="examDetail.scoreAuth == true">
                                    <div class="number answer">
                                        <svg-icon icon-class="right" class-name="icon-img"></svg-icon>
                                        <h1 class="num">{{ examDetail.paperType==4?'—':examDetail.rightCount }}</h1>
                                        <p class="titles">{{$t('WDNPC_WEB_WDNPC_WEB_ANSWER_RIGHT_DAO',{text:'答对(道)'})}}</p>
                                    </div>
                                    <div class="number incorrectly">
                                        <svg-icon icon-class="unright" class-name="icon-img"></svg-icon>
                                        <h1 class="num">{{ examDetail.paperType==4?'—':examDetail.errorCount }}</h1>
                                        <p class="titles">{{$t('WDNPC_WEB_WRONG_ANSWER_DAO',{text:'答错(道)'})}}</p>
                                    </div>
                                    <div class="number noAanswer">
                                        <svg-icon icon-class="unanswer" class-name="icon-img"></svg-icon>
                                        <h1 class="num">{{ examDetail.paperType==4?'—':examDetail.emptyCount }}</h1>
                                        <p class="titles">{{$t('WDNPC_WEB_NO_ANSWER_DAO',{text:'未答(道)'})}}</p>
                                    </div>
                                    <div class="number score">
                                        <svg-icon icon-class="totalCount" class-name="icon-img"></svg-icon>
                                        <h1 class="num">{{ examDetail.totalCount }}</h1>
                                        <p class="titles">{{$t('WDNPC_WEB_NUMBER_OF_GENERAL_QUESTIONS',{text:'总题数(道)'})}}</p>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="scroll-btn">
                        <div class="submit-btn" v-if="$route.query.type!=='historyList'">
                            <div v-if="haveFrequency">
                                <el-button v-if="examDetail.result == 1" type="primary" plain @click="submitExam">{{$t('WDNPC_WEB_REEXAMINATION',{text:'重考'})}}</el-button>
                                <el-button v-else type="primary" @click="submitExam">{{$t('WDNPC_WEB_REEXAMINATION',{text:'重考'})}}</el-button>
                            </div>
                            <el-button v-else-if="noFrequency" type="primary" class="noneNum">{{$t('WDNPC_WEB_NO_NUMBER_OF_EXAMS_LEFT',{text:'已无考试剩余次数'})}}</el-button>
                            <div v-else-if="again">
                                <el-button v-if="examDetail.result == 1" type="primary" plain @click="submitExam">{{$t('WDNPC_WEB_REEXAMINATION',{text:'重考'})}}</el-button>
                                <el-button v-else type="primary" @click="submitExam">{{$t('WDNPC_WEB_REEXAMINATION',{text:'重考'})}}</el-button>
                            </div>
                            <span v-else-if="overdue" @click="submitExam" class="end-exam">{{$t('WDNPC_WEB_THE_EXAM_HAS_ENDED',{text:'考试已结束'})}}</span>
                            <!-- <el-button v-else-if="overdue" type="primary" @click="submitExam">不在考试周期内</el-button> -->
                            <p v-if="haveFrequency" class="num">{{$t('WDNPC_WEB_A_TOTAL_OF_REMAINING_EXAM_OPPORTUNITIES',{text:'考试剩余机会：'})}}{{frequency}}{{$t('WDNPC_WEB_TOTAL',{text:'次/ 共'})}}{{examDetail.examCount}}{{$t('WDNPC_WEB_TIMES',{text:'次'})}}</p>
                            <p v-else-if="noFrequency" class="num">{{$t('WDNPC_WEB_A_TOTAL_OF_REMAINING_EXAM_OPPORTUNITIES_VJU',{text:'考试剩余机会：'})}}{{0}}{{$t('WDNPC_WEB_TOTAL',{text:'次/ 共'})}}{{examDetail.examCount}}{{$t('WDNPC_WEB_TIMES',{text:'次'})}}</p>
                        </div>
                        <div class="submit-btn-see" v-if="examDetail.questionAuth">
                            <span class="button" v-if="examDetail.isReadOver === 0">{{$t('WDNPC_WEB_PLEASE_LOOK_FORWARD_TO_THE_EXAMINATION_PAPER',{text:'试卷批阅中,敬请期待'})}}...</span>
                            <span class="button" v-if="examDetail.isReadOver === 1" @click="testPaper">{{$t('WDNPC_WEB_VIEW_TEST_PAPER_DETAILS',{text:'查看试卷详情'})}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            id="tip-dialog"
            :visible.sync="coursedialogVisible"
            width="400px"
            :before-close="handleClose">
            <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
            <p class="title">{{text1}}</p>
            <p class="text">{{text2}}</p>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleClose">{{submitText}}</el-button>
            </span>
        </el-dialog>
        <facedialog v-if="verification" :confirmdialog="confirmdialog" :address="verificationAddress" :failAddress="verificationFailAddress" :type=2 :pauseFrame="true"></facedialog>
    </div>
</template>

<script>
import SDK from '@wd/lowcode-pc'
export default {
  name: 'handPaper',
  show: true,
    data() {
        return {
            confirmdialog:{
              show:true,
            },
            frontendConfig:false,
            text1:'',
            text2:'',
            submitText:'',
            examDetail: {},
            examTime: '',
            coursedialogVisible: false,
            tipsContent: '',
            flag: false,
            startTime: '',
            endTime: '',
            frequency: null,
            userPaperId: this.$route.query.userPaperId,
            businessId: this.$route.query.businessId,
            examId: this.$route.query.examId,
            //taskType为1，则为从培训任务跳过来的
            taskType: this.$route.query.taskType || null, 
            // 考前人脸识别
            isFaceRecogBeforeExam: JSON.parse(sessionStorage.getItem('isFaceRecogBeforeExam')),
            // 考中人脸识别
            isFaceRecogDuringExam: JSON.parse(sessionStorage.getItem('isFaceRecogDuringExam')),
            // 考后人脸识别
            isFaceRecogCommitExam: JSON.parse(sessionStorage.getItem('isFaceRecogCommitExam')),
            // 入口
            source: this.$route.query.source,
            userName:'',
            haveFrequency: false,
            noFrequency: false,
            again: false,
            overdue:false,            
            img1: require('../../../assets/images/common/jige.png'),
            img2: require('../../../assets/images/common/bujige.png'),
            img3: require('../../../assets/images/common/weizuoda.png'),
            invalid: '',
            verification: false,
            verificationAddress: {},
            verificationFailAddress: {},
            returnPath: {},
            unanswered: false,
            readOver: false,
            cheat:0,//未违规
        };
    },
    filters:{
        timeFormatter(time) {
            const hours = Math.floor(time / 60 / 60);
            const minutes = Math.floor((time / 60) % 60);
            const seconds = Math.floor(time % 60);
            var hours1 = hours < 10 ? "0" + hours : hours;
            var minutes1 = minutes < 10 ? "0" + minutes : minutes;
            var seconds1 = seconds < 10 ? "0" + seconds : seconds;
            if(hours === 0){
                return minutes1 + '分' + seconds1 + '秒';

            } else if(minutes === 0){
                return seconds1 + '秒';
            } else {
                return hours1 + '时' + minutes1 + '分' + seconds1 + '秒';
            }
        },
    },
    mounted() {
        if (this.source == 'examDetails') {
            this.returnPath = {
                path:'/examDetails',
                query:{
                    bussinessId: this.businessId, 
                    examId: this.examId, 
                    type: this.taskType,
                    dymode: this.$route.query.dymode,
                    title: sessionStorage.getItem("examDetailsTitle"),
                    timeSlot: sessionStorage.getItem("examDetailsTimeSlot"),
                    startTime:sessionStorage.getItem("examDetailStartTime"),
                    endTime:sessionStorage.getItem("examDetailendTime")
                }
            }
        } else if (this.source == 'coursecatalog') {
            this.returnPath = {
                path:'/course/catalog',
                query:{
                    id:this.businessId,
                    taskType: this.taskType,
                    dymode: this.$route.query.dymode
                }
            }
        }
    },
    created() {
        this.findUserPaperSurface();
        this.returnButton();
       let frontendConfigList =  SDK.getConfigruation(this.$store).frontendConfig;
       frontendConfigList.map(item=>{
           if(item.key === 'isShowexamTag'){
               console.log('item---->777', item)
               this.frontendConfig = item.value;
           }
       })
    },
    destroyed(){
        window.removeEventListener('popstate', this.popstate, false);
    },
    methods: {
        // 浏览器回退按钮到训练营
        returnButton(){
            if (this.$route.name == 'handPaper') {
                let that = this;
                if (window.history && window.history.pushState) {
                    history.pushState(null, null, document.URL);
                    window.addEventListener('popstate', that.popstate, false)
                }
            }
        },
        popstate () {
            history.pushState(null, null, document.URL)
        },
        goBack() {
            if (this.$route.name == 'handPaper') {
                if (!(this.source == 'examDetails' && !this.examId)) {
                    this.$router.push(this.returnPath)
                }
            }
        },
        handleClose () {
            this.coursedialogVisible = false;
        },
        // 试卷信息
        findUserPaperSurface() {
            this.$api.examination.findUserPaperSurface(this.userPaperId).then((res) => {
                if(res.data && res.data != 'null'){
                    this.examDetail = res.data;
                    if (this.examDetail.invalidType == 1) {
                        this.invalid = this.$t('WDNPC_WEB_FACE_RECOGNITION_FAILED_TO_FORCE_SUBMISSION',{text:'人脸识别失败，强制交卷!'})
                    } else if (this.examDetail.invalidType == 2) {
                        this.invalid = this.$t('WDNPC_WEB_ANTISCREEN_CUTTING_LIMIT',{text:'防切屏超过次数限制，强制交卷!'})
                    } else if (this.examDetail.invalidType == 3) {
                        this.invalid = this.$t('WDNPC_WEB_THE_NUMBER_OF_TIMES_MORE_THAN_ONE',{text:'多人出现超过次数限制，强制交卷!'})
                    } else if (this.examDetail.invalidType == 4) {
                        this.invalid = this.$t('WDNPC_WEB_IT_IS_NOT_MANDATORY_TO_HAND_IN',{text:'不是本人超过次数限制，强制交卷!'})
                    } else if (this.examDetail.invalidType == 5) {
                        this.invalid = this.$t('WDNPC_WEB_THE_NUMBER_OF_TIMES_THAT_THE_NUMBER',{text:'检测不到人超过次数限制，强制交卷!'})
                    } else if (this.examDetail.invalidType == 7) {
                        this.invalid = this.$t('WDNPC_WEB_ADMINISTRATOR_FORCE_SUBMISSION',{text:'管理员强制交卷!'})
                    } else if (this.examDetail.invalidType == 9) {
                        this.invalid = this.$t('WDNPC_WEB_SYSTEM_SUBMISSION',{text:'系统交卷!'})
                    }
                    if(!this.examDetail.score){
                        // 是否有分数
                        if (this.examDetail.emptyCount == this.examDetail.totalCount) {
                            this.unanswered = true;
                        } else this.unanswered = false;
                    }else this.unanswered = false;
                    if ( !this.examDetail.isReadOver) {
                        this.readOver = true;
                    }
                    // this.examDetail.useDuration = this.timeFormatter(this.examDetail.useDuration);
                    this.startTime = (new Date(this.examDetail.trainExamStartTime)).getTime();
                    this.endTime = (new Date(this.examDetail.trainExamEndTime)).getTime();
                    var nowTime = (new Date()).getTime();
                    this.frequency = this.examDetail.examCount - this.examDetail.yetExamCount;
                    if (this.examDetail.isLimitTime) {
                        if ( nowTime > this.startTime && nowTime < this.endTime ) {
                            if (this.examDetail.examCount == 0) {
                                this.again = true;
                            } else if (this.frequency > 0) { 
                                this.haveFrequency = true;
                            } else {
                                this.noFrequency = true;
                            }
                        } else {
                            this.overdue = true;
                        }
                    } else {
                        if (this.examDetail.examCount == 0) {
                            this.again = true;
                        } else if (this.frequency > 0) {
                            this.haveFrequency = true;
                        } else {
                            this.noFrequency = true;
                        }
                    }
                }
            });
        },
        formateSeconds(endTime){
            let secondTime = parseInt(endTime)//将传入的秒的值转化为Number
            let min = 0// 初始化分
            let h =0// 初始化小时
            let result=''
            if(secondTime>60){//如果秒数大于60，将秒数转换成整数
                min=parseInt(secondTime/60)//获取分钟，除以60取整数，得到整数分钟
                secondTime=parseInt(secondTime%60)//获取秒数，秒数取佘，得到整数秒数
                if(min>60){//如果分钟大于60，将分钟转换成小时
                h=parseInt(min/60)//获取小时，获取分钟除以60，得到整数小时
                min=parseInt(min%60) //获取小时后取佘的分，获取分钟除以60取佘的分
                }
            }
            var xs = `${h.toString().padStart(2,'0')}` == '00' ? '' : `${h.toString().padStart(2,'0')}` + this.$t('WDNPC_WEB_HOURS',{text:'小时'});
            var fz = `${min.toString().padStart(2,'0')}` == '00' ? '' : `${min.toString().padStart(2,'0')} ` + this.$t('WDNPC_WEB_POINTS',{text:'分'});
            var mz = `${secondTime.toString().padStart(2,'0')}` == '00' ? '' : `${secondTime.toString().padStart(2,'0')}` + this.$t('WDNPC_WEB_SECONDS',{text:'秒'});
            result = xs + fz + mz;
            this.examTime = result;
        },
        // 再考一次
        submitExam() {
            this.$store.state.testPaper = false
            var nowTime = (new Date()).getTime();
            if (this.examDetail.isLimitTime) {
                const lateTime = this.examDetail.allowLateDuration*60*1000;
                if ( nowTime > this.startTime && nowTime < this.endTime ) {
                    if (this.examDetail.allowLateDuration > 0 && nowTime > this.startTime+lateTime) {
                        this.coursedialogVisible = true;
                        this.text1 = this.$t('WDNPC_WEB_PROMPT',{text:'提示'});
                        this.text2 = this.$t('WDNPC_WEB_YOU_HAVE_EXCEEDED_THE_LATEST_OPENING_TIME',{text:'您已超过最迟开考时间！'});
                        this.submitText = this.$t('WDNPC_WEB_OK',{text:'确定'});
                    } else if (this.isFaceRecogBeforeExam==1) {
                        this.verification = true;
                        this.confirmdialog.show = true;
                        this.verificationAddress = {
                            path:'/exam/resdExam',
                            query:{
                                userPaperId:this.userPaperId,
                                businessId:this.businessId,
                                examId: this.examId,
                                dymode: this.$route.query.dymode,
                                taskType: this.taskType,
                                source: this.source
                            }
                        };
                        this.verificationFailAddress = this.returnPath;
                    } else {
                        this.$router.push({
                            path:'/exam/resdExam',
                            query:{
                                userPaperId:this.userPaperId,
                                businessId:this.businessId,
                                examId: this.examId,
                                dymode: this.$route.query.dymode,
                                taskType:this.taskType,
                                source: this.source
                            }
                        }) 
                    }
                } else {
                    this.coursedialogVisible = true;
                    this.text1 = this.$t('WDNPC_WEB_PROMPT',{text:'提示'});
                    this.text2 = this.$t('WDNPC_WEB_NO_LONGER_WITHIN_THE_EXAM_CYCLE',{text:'已不在考试周期内'});
                    this.submitText = this.$t('WDNPC_WEB_OK',{text:'确定'});
                } 
            } else {
                if (this.isFaceRecogBeforeExam==1) {
                    this.verification = true;
                    this.verificationAddress = {
                        path:'/exam/resdExam',
                        query:{
                            userPaperId:this.userPaperId,
                            businessId:this.businessId,
                            examId: this.examId,
                            dymode: this.$route.query.dymode,
                            taskType: this.taskType,
                            source: this.source
                        }
                    };
                    this.verificationFailAddress = this.returnPath;
                } else {
                    this.$router.push({
                        path:'/exam/resdExam',
                        query:{
                            userPaperId:this.userPaperId,
                            businessId:this.businessId,
                            examId: this.examId,
                            dymode: this.$route.query.dymode,
                            taskType: this.taskType,
                            source: this.source
                        }
                    }) 
                }
            }
        },
        // 查看试卷详情
        testPaper() {
            if (this.readOver) {
                this.$message({
                    message: this.$t('WDNPC_WEB_PLEASE_WAIT_PATIENTLY_DURING_MARKING',{text:'阅卷中，请耐心等待'}),
                    type: 'warning'
                });
            } else {
                this.$store.state.testPaper = false
                this.$router.push({
                    path:'/exam/testPaperDetails',
                    query:{
                        userPaperId:this.userPaperId,
                        businessId:this.businessId,
                        examId: this.examId,
                        dymode: this.$route.query.dymode
                    }
                })
            }
        }
    }
};
</script>
<style lang="stylus" scoped>
.scroll{
    overflow:hidden;
}
.handPaper{
    /deep/.el-button--primary {
        background: #316FFF;
        border-color: #316FFF;
        span {
            color: #fff;
            font-size: 16px;
        }
        &.is-plain {
            color: #409EFF;
            background: #ecf5ff;
            border-color: #b3d8ff;
            span {
                color: #409EFF!important;
            }
            &:hover {
                background-color: #409eff;
                span {
                    color: #FFF!important;
                }
            }
        }
    }
    #tip-dialog {
        text-align: center;
        /deep/.el-dialog {
            border-radius: 10px;
            .el-dialog__header {
                display: none;
            }
            .el-dialog__body {
                padding-top: 40px;
                padding-bottom: 30px;
                .icon-img {
                    width: 50px;
                    height: 50px;
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 19px;
                }
                .title {
                    font-size: 24px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #1B2257;
                    line-height: 34px;
                    margin-bottom: 16px;
                }
                .text {
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }
            }
            /deep/.el-dialog__footer {
                padding: 0 65px 30px;
                text-align: center;
                .dialog-footer {
                    .el-button {
                        width: 240px;
                        height: 40px;
                        text-align: center;
                    }
                }
            }
        }
    }
}
</style>
<style lang="stylus" scoped>
@import "../asset/handPaper.styl"
</style>
