<template>
  <div>
    <!-- 人脸对比模板 -->
    <div class="face-compare" v-show="false">
      <!-- 身份证图片 -->
      <img id="orgImg" style="width:100px;height: 100px" crossorigin='anonymous' :src="orgImgElUrl" />
      <!-- 屏幕抓取图片 -->
      <img id="detImg" style="width:100px;height: 100px"  crossorigin='anonymous' src="" />
    </div>
    <!-- 考试直播窗口 -->
    <div class="exam-live-view">
      <!-- 异常记录 -->
      <div class="exception-record">
        <div class="exception-record-title" >
          <div>电脑摄像头:</div>
          <el-popover
            placement="bottom"
            width="234"
            trigger="hover"
            >
            <div
              v-if="exceptionRecord"
              style="padding: 0px 4px 4px 4px;box-sizing:border-box;"
            >
              <div
                style="margin-top:16px"
                class="warning-text"
                v-show="exceptionRecord.multiUserNum !=0"
              >
                <span style="margin-right:10px">⚠️警告</span>
                <!-- <span>检测多人出现: {{exceptionRecord.multiUserNum}}次,超过{{exceptionRecord.multiUserLimit}}次{{recordTips}}</span> -->
                <span>检测到多人出现{{exceptionRecord.multiUserNum}}次{{recordTips}}</span>
              </div>
              <div
                style="margin-top:16px"
                class="warning-text"
                v-show="exceptionRecord.notFaceNum !=0"
              >
                <span style="margin-right:10px">⚠️警告</span>
                <!-- <span>未检测到人脸: {{exceptionRecord.notFaceNum}}次,超过{{exceptionRecord.notFaceLimit}}次{{recordTips}}</span> -->
                <span>人脸检测异常{{exceptionRecord.notFaceNum}}次{{recordTips}}</span>
              </div>
              <div
                style="margin-top:16px"
                class="warning-text"
                v-show="exceptionRecord.notOwnNum !=0"
              >
                <span style="margin-right:10px">⚠️警告</span>
                <span>检测到不是本人{{exceptionRecord.notOwnNum}}次{{recordTips}}</span>
                <!-- <span>检测到不是本人: {{exceptionRecord.notOwnNum}}次,超过{{exceptionRecord.notOwnLimit}}次{{recordTips}}</span> -->
              </div>
              <div
                style="margin-top:16px"
                class="warning-text"
                v-show="exceptionRecord.switchScreenNum !=0"
              >
                <span style="margin-right:10px">⚠️警告</span>
                <!-- <span>检测到切屏: {{exceptionRecord.switchScreenNum}}次,超过{{exceptionRecord.switchScreenNumLimit}}次{{recordTips}}</span> -->
                <span>检测到切屏{{exceptionRecord.switchScreenNum}}次，请勿切换页面，遵守考试纪律！</span>
              </div>
              <div
                style="margin-top:16px"
                class="warning-text"
                v-show="exceptionRecord.adminAlertNum !=0"
              >
                <span style="margin-right:10px">⚠️警告</span>
                <!-- <span>检测到切屏: {{exceptionRecord.switchScreenNum}}次,超过{{exceptionRecord.switchScreenNumLimit}}次{{recordTips}}</span> -->
                <span>管理员检测到您有考试作弊倾向{{exceptionRecord.adminAlertNum}}次，请遵守考试规则</span>
              </div>
            </div>
            <div
              style="text-align: center"
              v-show="exceptionRecord.multiUserNum==0 && exceptionRecord.notFaceNum==0 && exceptionRecord.notOwnNum==0 && exceptionRecord.switchScreenNum == 0 && exceptionRecord.adminAlertNum == 0">
                无异常记录
            </div>
            <span
              slot="reference"
            >
            <span v-if="exceptionRecord">
              <span v-if="exceptionRecord.multiUserNum==0 && exceptionRecord.notFaceNum==0 && exceptionRecord.notOwnNum==0 && exceptionRecord.switchScreenNum == 0 && exceptionRecord.adminAlertNum == 0"></span>
              <span v-else>考试异常记录</span>
            </span>
            </span>
          </el-popover>
        </div>
        <div
          class="detection-btn"
          @click="handleshowCheck"
          v-show="showDetectionBtn"
          :style="{'z-index': showDetectionBtn ? '11': ''}"
        >
          检测设备
        </div>
      </div>
      <!--   摄像头播放   -->
      <div class="realy-player-box">
        <div ref="video" v-loading="loading"></div>
        <!-- <div class="realy-player-message"> -->
          <div class="exception-message">
            <!-- <div class="exception-message1" @click="operationManual()">操作手册</div> -->
            <div class="exception-message2">
              <span class="exception-message-color1">无实时画面?</span>
              <span class="exception-message-color2" @click="pushFlow()">点击刷新</span>
            </div>
          </div>
        <!-- </div> -->
      </div>
    </div>
    <DeviceCheckDialog
      v-if="showCheckDialog"
      @close="showCheckDialog=false"
      ref="checkout"
    ></DeviceCheckDialog>
  </div>
</template>
<script>
import * as faceapi from "face-api.js";
import DeviceCheckDialog from './dialogs/check/DeviceCheckDialog';
// import { client as screenSender } from '../libs/screen';
import { client as cameraSender } from '../libs/camera';
import { exportMethod } from '../../../utils/download';
import baseUrl from '@/config/url'

async function processCamera(vm,data) {
  const client = cameraSender( data.userId , data.skuAppId, data.userSig, vm);
    // let chatsTimer;
    vm.clientT = client;

    client.onLivingL = (localStream) => {
      // vm.livingState = 'starting';
      vm.tid =setInterval(() => {
        // console.log('10秒钟+++++++++')
        console.log('2分钟++++++++');
        const frame = localStream.getVideoFrame();
        if(frame){
          if (vm.monitorNum < 12) {
            console.log('===========monitorNum======',vm.monitorNum)
            ++vm.monitorNum
          }
          // localStorage.setItem('localStream',frame)
          // console.log(frame,'localStream2211221112');
          const img = document.getElementById("detImg");
          img.src = frame;
          vm.nomalImg = frame;
          vm.fnInit()
        }

      }, 120000);
      vm.$api.exam.joinMix({
        isDoubleEquipment: vm.controlSwitch.isOpenPhoneCamera == 1 ? true : false,
        roomId: vm.roomId,
      })
    };
    client.onLiveStarting = () => {
      vm.livingState = 'living';
      vm && vm.$once('hook:beforeDestroy', () => {
      });
    };
    client.onLiveStoping = () => {
      vm.livingState = 'finish';
      clearInterval(vm.tid);
    }
    vm && vm.$once('hook:beforeDestroy', async () => {
      clearInterval(vm.tid);
      localStorage.removeItem('LiveParam');
      try {
        await client.leaveRoom();
        console.log('camera leave room');
      } catch(error) {
        console.error(error);
      }
    })
    return client;
}


export default {
    name: "liveView",
    components:{
      DeviceCheckDialog
    },
    props: {
      // 控制是否显示直播 是否检测异常
      controlSwitch: {
        type: Object,
        default: () => {}
      },
      whetherCapture: {
        type: Boolean,
        default: false
      },
      showDetectionBtn: {
        type: Boolean,
        default: false
      },
    },
    data(){
      return{
        showCheckDialog: false,
        distance: 0, // 对比误差值
        desc: [], // 样本矩阵
        orgImgEl: null,
        detImgEl: null,
        nets: "ssdMobilenetv1", // 模型
        params: {}, // 开直播参数
        monitorNum: -1,
        loading: true,
        monitorType:'',//2切屏（pc需抓拍） 6:管理警告(pc需抓拍);7:管理员强制交卷(pc需抓拍);
        orgImgElUrl: '', //人脸图
        nomalImg: '', // 当前帧
        exceptionRecord: '', // 异常记录
        // recordTips: '将强制交卷，请勿遮挡脸部，确保您的正脸在摄像像头拍摄范围内。',
        recordTips: '，请勿遮挡脸部，确保您的正脸在摄像头拍摄范围内。',
        tid: null, // 人脸对比轮询
        livingState: '', // 监听直播推流成功
        roomId: null, // 直播房间的id
        originImgForData:'',// 从服务端获取到的人脸图片
        clientT:'',
        localStream:''
      }
    },
    watch: {
      'monitorType'(val) {
        if (val == 6 || val == 7 || val == 2) {
          // this.fnInit();
          //   console.log(this.clientT.getLocalStream().getVideoFrame(),val,'且屏幕++++++1221212')
            //
            // console.log(this.localStream.getVideoFrame(),val,'且屏幕++++++')
            if(this.clientT.getLocalStream().getVideoFrame()){
                this.saveCapture(this.clientT.getLocalStream().getVideoFrame(), val)
            }
        }
      },
    },
    created() {
      this.params = Object.assign(this.params,this.$route.query);
      localStorage.setItem('LiveParam',JSON.stringify(this.params))
      // 获取身份证人脸图片
      this.getFace();
    },
    async mounted() {
        let params = {
          channel: 1,
          businessId: this.params.businessId,
          examId: this.params.examId,
          businessType: this.params.taskType,
          userPaperId: this.$route.query.userPaperId
        }
        const res = await this.$api.exam.queryLinkLiveData(params)
        // localStorage.setItem('processCamera',res.data)
        this._cameraClient = await processCamera(this,res.data);
        this._cameraClient.joinRoom(res.data.roomId);
        this.roomId = res.data.roomId;
        setTimeout(() => {
          this.clickSecondConfirmPop('start');
        }, 5000)
    },
    methods:{



        getLocalStreamVideoFrame(){

        },
        async cutOriginImageFace(){
          await faceapi.loadFaceRecognitionModel("/models");

          await faceapi.nets[this.nets].loadFromUri("/models");
          // 截原始图片的人脸
          this.orgImgEl = document.getElementById("orgImg");
          if(!this.orgImgElUrl)return
          const orgImgElDetections = await faceapi.detectAllFaces(this.orgImgEl);

          const originFaceImages = await faceapi.extractFaces(this.orgImgEl, orgImgElDetections);
          if(!originFaceImages.length)return
          let originImage = this.convertCanvasToImage(originFaceImages[0]);

          this.orgImgEl.src = originImage.src || this.orgImgElUrl;
        },
        // canvas 转image
        convertCanvasToImage(canvas) {
          //新Image对象，可以理解为DOM
          var image = new Image();
          // canvas.toDataURL 返回的是一串Base64编码的URL，当然,浏览器自己肯定支持
          // 指定格式 PNG
         if (canvas) {
          image.src = canvas.toDataURL("image/png");
         }
          return image;
        },
        // 初始化模型加载
        async fnInit() {
          console.log('=================初始化模型加载====================');
            await faceapi.loadFaceRecognitionModel("/models");
            await faceapi.nets[this.nets].loadFromUri("/models");

            // const frame = JSON.parse(JSON.stringify(localStorage.getItem('localStream')))
            // 如果存在抓拍的图片，detImg的src就是frame；frame每两分钟抓拍一次   存在localStorage里边会出现占满的情况
            // if(frame){
            //   const imgs = document.getElementById("detImg");
            //   imgs.src = frame;
            //   this.nomalImg = frame;
            // }
            // 节点属性化
            this.orgImgEl = document.getElementById("orgImg");
            this.detImgEl = document.getElementById("detImg");
            if (!this.detImgEl.src) return
            if(!this.orgImgElUrl)return
            const detections = await faceapi.detectAllFaces(this.detImgEl);
            const faceImages = await faceapi.extractFaces(this.detImgEl, detections);
            let endImage = this.convertCanvasToImage(faceImages[0]);
            const img = document.getElementById("detImg");
            img.src = endImage;
            this.detImgEl.src = endImage.src;
            this.loading = false // 直播加载loading结束
              // 如果弹框一直存在 不抓取异常
              if (this.whetherCapture) return;
              console.log(this.monitorNum,'首次截取人脸============================',this.monitorType)
              // 首次截取人脸
              if (this.monitorNum == 0) {
                this.saveCapture(this.nomalImg, this.monitorNum)
                return
              } else if (this.monitorType == 2 || this.monitorType == 6 || this.monitorType == 7) {
                // 异常截取人脸
                // 2:切屏次数过多;6:管理警告;7:管理员强制交卷;8:结束;9:系统交卷;
                console.log('异常截取人脸',this.monitorType)
                let monitorType = this.monitorType;
                this.saveCapture(this.nomalImg, monitorType)
              }
              console.log(faceImages.length,'检测有'+faceImages.length+'个人脸===========')
            if (!faceImages.length && this.controlSwitch.isMonitorNotFace == 1) {
              // console.log('未检测到人脸------',this.nomalImg)
              this.saveCapture(this.nomalImg, 5)
            } else if (faceImages.length > 1 && this.controlSwitch.isMonitorMultiUser == 1) {
              console.log('检测到多人----')
              this.saveCapture(this.nomalImg, 3)
            } else {
              console.log('检测到一人进行比对---')
              //转图片矩阵数据
              this.desc = [
                await faceapi.computeFaceDescriptor(this.orgImgEl),
                await faceapi.computeFaceDescriptor(this.detImgEl),
              ];
              let distance = faceapi
                .euclideanDistance(this.desc[0], this.desc[1])
                .toFixed(2);
              // 不是本人抓取图片
              if (distance > 0.5 && this.controlSwitch.isMonitorNotOwn == 1) {
                this.saveCapture(this.nomalImg, 4);
              }
              this.distance = distance;
            }
        },
        clickSecondConfirmPop(flag) {
          if (flag == 'start') {
              this.startLiving();
          } else {
              this.stopLiving();
          }
        },
        startLiving() {
          this._cameraClient.startLiving(this.$refs.video);
          this.$nextTick(()=>{
              this.fnInit();
              this.cutOriginImageFace();

          })
        },
        async stopLiving() {
            this.livingTips_html = ''
            this.studentList = [];

            this._cameraClient.stopLiving();
            this.livingState = 'finish';
        },
        handleshowCheck() {
          this.showCheckDialog = true;
          this.$emit('showShade')
        },
        // 异常上报
        async saveCapture(url, type) {
          let monitorTime = this.getNowTime();
          let data = {
            businessId: this.params.userPaperId,
            businessType: 2,
            businessTypeEnum: null,
            monitorTime: monitorTime,
            monitorType: type,
            monitorTypeEnum: null,
            photoChannel: 1,
            photoChannelEnum: null,
            userPhotoBase64: url,
            examId: this.params.examId,
          }
          console.log('抓拍保存参数',data);
          let res = await this.$api.exam.saveCapture(data)
          console.log(res,'发送抓拍')
        },
        // 获取身份证人脸图
        async getFace() {
          let res = await this.$api.exam.getFace();
          // if (res.success) {
            if(res.data){
                this.orgImgElUrl = 'data:image/png;base64,' + res.data;
            }
            // console.log('data:image/png;base64,' + res.data,'检测到身份证照片====')
          // }
        },
        // 获取当前抓拍时间
        getNowTime() {
          let yy = new Date().getFullYear();
          let mm = new Date().getMonth()+1;
          let dd = new Date().getDate();
          let hh = new Date().getHours();
          let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
          let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
          let time = yy+'-'+mm+'-'+dd+' '+hh+':'+mf+':'+ss;
          return time;
        },
        /**
         * @description 操作手册
         */
        operationManual(){
          console.log('operationManual');
          let myObj = {
                method: 'get',
                url: baseUrl.API+'/exam/v1/userExam/download',
                fileName: '学员考试操作手册.pdf',
            }
          exportMethod(myObj)
        },
        /**
         * @description 点击刷线  重新拉流
         */
        async pushFlow(){
          console.log('pushFlow');
          this.stopLiving()
          this.$emit('pushFlow',false)
        },
    },
    destroyed() {
      localStorage.removeItem('LiveParam');
      clearInterval(this.tid);
      this.stopLiving()
    }
}
</script>
<style lang="stylus" scoped>
  .face-compare {
    img {
       width: 256px;
    }
    #detImg {
      min-height: 2px;
    }
  }
  .exam-live-view {
    margin-top: 39px;
    .exception-record {
      margin-bottom: 12px;
      .exception-record-title {
        color: #1B2257;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          color: #FF5454;
          display: inline-back;
          cursor:pointer;
        }
      }
      .detection-btn {
        z-index: 9;
        font-size: 14px;
        color: #fff;
        padding: 6px 9px;
        bbox-sizing: border-box;
        background: #316FFF;
        border-radius: 4px;
        cursor:pointer;
      }
    }
    .realy-player-message{
      position: fixed;
      bottom:38px;
    }
    .exception-message{
      display: flex;
      justify-content: right;
      align-items: center;
      padding:0 10px;
      .exception-message1{
        font-size:14px;
        color:#fff;
        text-decoration: underline;
        cursor: pointer;
        margin-top: 20px;
      }
      .exception-message2{
        font-size:12px;
        color:#fff;
        position: absolute;
        bottom: 10px;
        .exception-message-color2{
          font-size:14px;
          text-decoration: underline
          padding-left:2px;
          cursor: pointer;
        }
      }
    }
    .realy-player-box {
      width: 264px;
      height: 264px;
      border-radius: 4px;
      background: black;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      position:relative;
    }
  }
</style>
