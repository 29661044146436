<template>
  <el-dialog
    class="submit-dialog"
    :visible.sync="warningVisible"
    width="400px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    >
      <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
      <p class="titles">警告</p>
      <p class="tips-red" v-show="warningData.monitorType != 7 && warningData.monitorType != 6">
        {{warningData.monitorType | dataFormat}}:
        <span style="font-weight: bold;font-size:17px">{{warningData.count || 0 }}</span> 次
      </p>
      <p class="wraning-msg" v-if="warningData.forceCommit && warningData.monitorType == 7">
        系统将强制提交试卷!
      </p>
      <p class="wraning-msg" v-if="!warningData.forceCommit && warningData.monitorType == 6">
        <span v-if="warningData.informType == 1">
          {{warningData.informContent || '管理员检测到您有作弊倾向,请遵守考试规则!'}}
        </span>
        <!-- <span>
          管理员检测到您有作弊倾向,请遵守考试规则!
        </span> -->
      </p>
      <!-- <p class="wraning-msg" v-show="warningData.monitorType != 7 && warningData.monitorType != 6">
        请勿遮挡脸部,确保您的正脸在摄像头拍摄范围内
      </p> -->
      <span slot="footer" class="warning-dialog-footer">
        <span v-if="warningData.forceCommit && warningData.monitorType == 7"></span>
        <el-button v-else type="primary" style="width: 240px" @click="handleClose">知道了</el-button>
      </span>
    </el-dialog>
</template>
<script>
let that;
export default {
  props: {
    warningVisible: {
      type: Boolean,
      default: false
    },
    warningData: {
      type: Object,
      default: () => {}
    }
  },
  filters: {
    dataFormat(msg) {
      let message = ''
       switch (msg) {
        case 2:
          message = '检测到切屏'
          break;
        case 3:
          message = '检测到多人出现'
          break;
        case 4:
          message = '检测到不是本人'
          break;
        case 5: 
          message = '检测不到人'
          break;
        default:
      }
      return message
    },
    limit(type) {
      let num = 0;
      switch (type) {
        case 2:
          num = that.exceptionRecord.switchScreenNumLimit
          break;
        case 3:
          num = that.exceptionRecord.multiUserLimit
          break;
        case 4:
          num = that.exceptionRecord.notOwnLimit
          break;
        case 5: 
          num = that.exceptionRecord.notFaceLimit
          break;
        default:
      }
      return num;
    }
  },
  watch:{
    warningVisible(val){
      console.log('===================管理端警告============',val);
      if(val){
        if(this.warningData.forceCommit && this.warningData.monitorType == 7){
          console.log('===================强制交卷============');
          setTimeout(() => {
            this.$emit('submit')
            this.$emit('close',this.warningData.monitorType)
          },3000)
        }
      }
    }
  },
  data() {
    return {
      exceptionRecord: {}
    }
  },
  beforeCreate: function () {
    that = this;
  },
  methods: {
    handleClose() {
      if (this.warningData.forceCommit) {
        this.$emit('submit')
        this.$emit('close',this.warningData.monitorType)
      } else {
       this.$emit('close',this.warningData.monitorType)
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
  .submit-dialog {
    .titles {
      color: #1B2257;
      font-size: 24px
    }
    .icon-img {
      font-size 50px;
    }
    .tips-red {
      color: #FD220F;
      font-size: 16px;
      line-height: 24px;
    }
    .wraning-msg {
      font-size: 16px;
      color: #666;
      line-height: 24px;
    }
    /deep/.el-dialog__footer {
      text-align: center !important; 
      .warning-dialog-footer {
        .el-button {
          width: 240px !important;
          height: 40px;
          padding: 0 !important;
          margin: 0 auto;

          &.el-button--default {
            span {
              color: #666;
              font-size: 16px;
            }
          }

          &.btn2 {
            &.el-button--default {
              span {
                color: #316FFF;
              }
            }

            border-color: #316FFF;
          }
        }
      }
    }
  }
</style>