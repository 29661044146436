<template>
    <div>
        <div v-for="(option, index3) in subDtoList" :key="index3" :id="'sub' + option.id">
            <div class="subBox">
                <div class="subTitle">
                    <div class="left">
                        <span class="xuhao">{{ question.questionNo + 1 }}.{{ index3 + 1 }}</span>
                        <span class="topicType">{{ topicType(option) }}</span>
                    </div>
                    <span class="content" v-html="option.title" />
                </div>
                <div class="signIcon" @click="markClick(option)">
                    <span class="mark-btn" :class="{ active: option.mark }" />
                    <span v-if="!option.mark" class="text">{{ $t('WDNPC_WEB_MARK', { text: '标记' }) }}</span>
                    <span v-else class="text">{{ $t('WDNPC_WEB_CANCEL', { text: '取消' }) }}</span>
                </div>
            </div>
            <div class="topicOptions">
                <!-- 简答题 -->
                <div v-if="option.type === 5">
                    <el-input
                        type="textarea"
                        :rows="7"
                        :placeholder="$t('WDNPC_WEB_ENTER_THE_ANSWER_LIMIT', { text: '请输入答案…（限制2000字以内）' })"
                        maxlength="2000"
                        show-word-limit
                        v-model="option.myAnswers"
                        @change="val => getop2(val, option)"
                    >
                    </el-input>
                    <!-- 上传 -->
                    <uploadZip v-if="option.uploadFlag === 1" :question="option" @enclosureData="enclosureData"/>
                </div>
                <!-- 填空题 -->
                <div v-else-if="option.type === 6" class="completionBox">
                    <div class="Completion-item" v-for="(completion,completionIndex) in option.completionAnswer" :key="'comple'+completionIndex">
                        <span class="label">第{{completionIndex+1}}空：</span>
                        <el-input
                            class="CompletionAnswers"
                            v-model="completion.answer"
                            placeholder="请输入答案"
                            @change="(val) => getop3(val,option)"
                        />
                    </div>
                </div>
                <template v-else>
                <li
                    v-for="(subOption, index4) in option.optionList"
                    :key="subOption.sort"
                    class="answer"
                    @click="handleClickOption(option, option.optionList, option.type, subOption, index3, index4)"
                >
                    <!-- 试题类题型(1:单选,2:多选,3:判断,4:高阶题) -->
                    <span
                        v-if="option.type == 1 || option.type == 3 || option.type == 4 "
                        class="option-btn"
                        :class="{
                            active: subOption.isAnswers,
                        }"
                    />
                    <el-checkbox
                        @change="val => getop(val, option, option.optionList)"
                        v-if="option.type == 2"
                        v-model="option.optionList[index4].isAnswers"
                    >
                        <span
                            class="option-text"
                            :class="{
                                active: subOption.isAnswers,
                            }"
                        >
                            {{ subOption.optionNumber }}
                        </span>
                        <span v-html="subOption.optionContent" class="option-text-content">
                            {{ subOption.optionContent }}
                        </span>
                    </el-checkbox>
                    <template v-if="option.type == 1 || option.type == 3 || option.type == 4">
                        <span
                            class="option-text"
                            :class="{
                                active: subOption.isAnswers,
                            }"
                        >
                            {{ subOption.optionNumber }}
                        </span>
                        <span v-html="subOption.optionContent" class="option-text-content">
                            {{ subOption.optionContent }}
                        </span>
                    </template>
                </li>
                </template>

            </div>
        </div>
    </div>
</template>

<script>
import uploadZip from "../components/upload-zip";

export default {
    components:{uploadZip},
    props: {
        subDtoList: {
            type: Array,
            default() {
                return []
            },
        },
        question: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    data() {
        return {}
    },
    methods: {
        // 点击标记
        markClick(question) {
            question.mark = !question.mark
            question.markFlag = !question.markFlag
            // 处理数据不更新问题
            this.$emit('forceUpdate')
        },
        handleClickOption(option, optionList, type, subOption, index3, index4) {
            this.$emit('handleClickOption', option, optionList, type, subOption, index3, index4)
        },
        getop(val, subOption, optionList) {
            this.$emit('getop', val, subOption, optionList)
        },
        getop2(val, option) {
            this.$emit('getop2', val, option)
        },
        getop3(val, option) {
            this.$emit('getop3', val, option)
        },
        enclosureData(datas,question) {
            this.$emit('enclosureData', datas, question)
        },
        
        topicType(item) {
            // 获取当前题目类型
            const typeArr = {
                0: '实操',
                1: '单选',
                2: '多选',
                3: '判断',
                4: '高阶',
                5: '问答',
                6: '填空',
            }
            return `【${typeArr[item.type]}题 ${item.score}分】`
        },
    },
}
</script>

<style lang="less" scoped>
// 组合题样式
.combination {
    margin-left: 50px;
    .subTitle {
        margin-bottom: 16px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #333333;
        line-height: 20px;
        flex: 1;
        .left {
            float: left;
            font-weight: 600;
        }
        .content {
            /deep/ .video-box-wd{
                width: 400px !important;
                height: 225px !important;
                video{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .answer {
        .el-checkbox {
            display: flex;
            /deep/ .el-checkbox__input {
                top: 5px;
            }
        }
    }
    .option-text-content {
        /deep/ p {
            white-space: pre-wrap;
        }
    }
}
.subBox {
    display: flex;
    .signIcon {
        width: 80px;
        text-align: right;
        cursor: pointer;
        .mark-btn {
            display: inline-block;
            margin-right: 4px;
            margin-bottom: 1px;
            height: 3px;
            border-radius: 4px;
            border: 7px solid #f2f2f1;
            border-bottom: 6px solid #fff;
            background: #f2f2f1;
            vertical-align: bottom;
            &.active {
                background: #ff8844;
                border: 7px solid #ff8844;
                border-bottom: 6px solid #fff;
                color: #fff;
            }
        }
        .text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
        }
    }
}
</style>

<style lang="stylus" scoped>
@import '../asset/testPaper.styl';
</style>
