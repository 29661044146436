import Axios from 'axios';
import URL from '../config/url';
const baseURL = URL.API;

const service = Axios.create({ withCredentials: false, baseURL })

// 请求拦截器
service.interceptors.request.use(config => {
  localStorage.getItem('token') && (config.headers['token'] = localStorage.getItem('token'))
  localStorage.getItem("organ") && (config.headers['organ'] = localStorage.getItem("organ"))
  localStorage.getItem("actor") && (config.headers['actor'] = localStorage.getItem("actor"))
  localStorage.getItem('branch') && (config.headers['branch'] = localStorage.getItem('branch'))
  return config
}, err => {
  return Promise.reject(err)
})

// 响应拦截器
service.interceptors.response.use(response => {
  return response.data
}, err => {
    console.log('出错了~~',err)
  return Promise.reject(err)
})


export default service
