<template>
  <div class="iframe">
    <iframe
      id="lanqiao"
      :src="srcData"
      frameborder="0"
      width="100%"
    >
    </iframe>
  </div>
</template>
<script>
import { Loading } from 'element-ui';
export default {
  name: "fieldTest",
  show: true,
  props:{
    parameter:{
      type:Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading:null,
      srcData: ""
    };
  },
  mounted() {
    this.getDrillUrlForExam();

  },
  methods: {
    getDrillUrlForExam() {
      this.loading = Loading.service({
          lock:true,    //是否锁定
          text:"加载中...",   //显示在加载图标下方的加载文案
          background:'rgba(0,0,0,.7)',   //遮罩背景色
      });
      
      this.$api.exam.getDrillUrlForExam(this.parameter).then((res) => {
        this.srcData = res.data.url;
        this.setIframeHeight(document.getElementById('lanqiao'));
        setTimeout(() => {
          this.loading.close();
        }, 3000);
      })
    },
    setIframeHeight(iframe) {
      if (iframe) {
        var iframeWin =
          iframe.contentWindow || iframe.contentDocument.parentWindow;
        if (iframeWin.document.body) {
          iframe.height =
            iframeWin.document.documentElement.scrollHeight ||
            iframeWin.document.body.scrollHeight;
        }
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
#lanqiao{
  height:100%;
}
.iframe {
  height: 100%;
}
</style>